import React from 'react';
import { Modal } from 'antd';
import { handleGlobalModal } from '../../ducks/Global/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

export const GlobalModal = ({ buttons, style }) => {
  const dispatch = useDispatch();
  const isGlobalModalOpen = useSelector(
    (state) => state.global.isGlobalModalOpen
  );
  const globalModalContent = useSelector(
    (state) => state.global.globalModalContent
  );

  const renderButtons = () => {
    return buttons
      ? buttons.map((button) => (
          <Button type={button.type} onClick={() => button.action()}>
            {button.text}
          </Button>
        ))
      : null;
  };

  return (
    <div style={style}>
      <Modal
        className='dark'
        open={isGlobalModalOpen}
        onCancel={() => dispatch(handleGlobalModal(false))}
        footer={null}
        centered
      >
        {globalModalContent}
        {renderButtons()}
      </Modal>
    </div>
  );
};
