export const SET_FORM_VALUES = 'SET_FORM_VALUES';

export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR';

export const CLEAR_SUCCESS_MESSAGE = 'CLEAR_SUCCESS_MESSAGE';
export const OPEN_REVIEW_MODAL = 'OPEN_REVIEW_MODAL';
export const HANDLE_GLOBAL_MODAL = 'HANDLE_GLOBAL_MODAL';
export const SET_FOOTER_NAVIGATION = 'SET_FOOTER_NAVIGATION';
export const SET_MOBILE_VIEW = 'SET_MOBILE_VIEW';
export const HANDLE_DRAWER_MENU = 'HANDLE_DRAWER_MENU';
export const SET_MACRONUTRIENT_ANSWERS = 'SET_MACRONUTRIENT_ANSWERS';

export const SAVE_MACRONUTRIENT_ANSWERS = 'SAVE_MACRONUTRIENT_ANSWERS';
export const SAVE_MACRONUTRIENT_ANSWERS_SUCCESS =
  'SAVE_MACRONUTRIENT_ANSWERS_SUCCESS';
export const SAVE_MACRONUTRIENT_ANSWERS_ERROR =
  'SAVE_MACRONUTRIENT_ANSWERS_ERROR';

export const GET_MACRONUTRIENT_ANSWERS = 'GET_MACRONUTRIENT_ANSWERS';
export const GET_MACRONUTRIENT_ANSWERS_SUCCESS =
  'GET_MACRONUTRIENT_ANSWERS_SUCCESS';
export const GET_MACRONUTRIENT_ANSWERS_ERROR =
  'GET_MACRONUTRIENT_ANSWERS_ERROR';
export const HANDLE_WELCOME_MODAL = 'HANDLE_WELCOME_MODAL';
export const HANDLE_GENERATE_MEALPLAN_MODAL = 'HANDLE_GENERATE_MEALPLAN_MODAL';

export const SAVE_ADVANCED_MODE_MACROS = 'SAVE_ADVANCED_MODE_MACROS';
export const SAVE_ADVANCED_MODE_MACROS_SUCCESS =
  'SAVE_ADVANCED_MODE_MACROS_SUCCESS';
export const SAVE_ADVANCED_MODE_MACROS_ERROR =
  'SAVE_ADVANCED_MODE_MACROS_ERROR';

export const SET_MEAL_PLAN_ANSWERS = 'SET_MEAL_PLAN_ANSWERS';

export const SET_ADVANCED_MODE_MACROS = 'SET_ADVANCED_MODE_MACROS';

export const SET_MACRO_CALCULATION_RESULTS = 'SET_MACRO_CALCULATION_RESULTS';

export const HANDLE_SPECIAL_OFFER_MODAL = 'HANDLE_SPECIAL_OFFER_MODAL';
