import { put, takeLatest, call } from 'redux-saga/effects';
import axios from 'axios';
import {
  SEND_EMAIL,
  SAVE_MACRONUTRIENT_ANSWERS,
  GET_MACRONUTRIENT_ANSWERS,
} from './constants';
import {
  sendEmailSuccess,
  sendEmailError,
  saveMacronutrientAnswersSuccess,
  saveMacronutrientAnswersError,
  getMacronutrientAnswersSuccess,
  getMacronutrientAnswersError,
} from './actions';

export function* sendEmail({ emailData, successMessage }) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ emailData });

  try {
    yield call(axios.post, emailData.url, body, config);
    yield put(sendEmailSuccess(successMessage));
  } catch (error) {
    yield put(sendEmailError('Error sending email, please try again later'));
    console.error('Error sending email:', error);
  }
}

export function* saveMacronutrientAnswers({ macronutrientAnswers }) {
  const token = localStorage.getItem('token');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': token,
    },
  };

  const body = JSON.stringify({ macronutrientAnswers });

  try {
    const payload = yield call(
      axios.post,
      '/api/users/macronutrient-answers',
      body,
      config
    );
    yield put(saveMacronutrientAnswersSuccess(payload.data));
  } catch (error) {
    yield put(sendEmailError('Error sending email, please try again later'));
    yield put(saveMacronutrientAnswersError(error));
  }
}

export function* getMacronutrientAnswers() {
  try {
    const payload = yield call(axios.get, '/api/users/macronutrient-answers');
    yield put(getMacronutrientAnswersSuccess(payload.data));
  } catch (err) {
    console.log(err);
    yield put(getMacronutrientAnswersError(err));
  }
}

export default function* globalSaga() {
  yield takeLatest(SEND_EMAIL, sendEmail);
  yield takeLatest(SAVE_MACRONUTRIENT_ANSWERS, saveMacronutrientAnswers);
  yield takeLatest(GET_MACRONUTRIENT_ANSWERS, getMacronutrientAnswers);
}
