const lowCalories = (bmr, calculatedCalories, gender) => {
  const minCalories = gender === 'male' ? 1500 : 1200;
  if (calculatedCalories < bmr) {
    return (
      <>
        <p className='subtitle bold mb-20'>⚠️ Important Notice</p>
        <p className='subtitle mb-20'>
          Your calculated calorie intake ({calculatedCalories}) is below your
          Basal Metabolic Rate (BMR) of {bmr} calories. Consuming fewer calories
          than your BMR might not support essential bodily functions. It's
          recommended to stay above your BMR to maintain good health.
        </p>
        <p className='subtitle'>
          Please choose a less aggressive weight loss goal.
        </p>
      </>
    );
  } else if (calculatedCalories < minCalories) {
    return (
      <>
        <p className='subtitle bold mb-20'>⚠️ Important Notice</p>
        <p className='subtitle mb-20'>
          Your calculated calorie intake (${calculatedCalories}) is quite low.
          It’s generally recommended not to consume less than ${minCalories}{' '}
          calories daily for ${gender === 'male' ? 'men' : 'women'} to avoid
          potential health risks.
        </p>
        <p className='subtitle'>
          Please choose a less aggressive weight loss goal.
        </p>
      </>
    );
  } else {
    return (
      <>
        <p className='subtitle bold mb-20'>⚠️ Important Notice</p>
        <p className='subtitle mb-20'>
          Your calorie intake (${calculatedCalories}) is within a healthy range
          for your BMR (${bmr} calories).
        </p>
        <p className='subtitle'>
          Please choose a less aggressive weight loss goal.
        </p>
      </>
    );
  }
};

export { lowCalories };
