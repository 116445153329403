import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { features } from '../data/features';

export const AboutApp = () => {
  const navigate = useNavigate();
  return (
    <div className='landing-page'>
      <div className='section gray'>
        <div className='content' style={{ paddingTop: '0px' }}>
          <div className='grid grid justify-center mb-50'>
            <p className='title mb-20' style={{ textAlign: 'center' }}>
              The <span className='text-gradient underlined-title'>App</span>
            </p>

            <p
              className='big-subtitle'
              style={{
                width: '100%',
                maxWidth: '600px',
                textAlign: 'center',
              }}
            >
              My game-changing app, developed with proprietary methodologies,
              takes your transformation to a whole new level.
            </p>
          </div>
          <div className='container'>
            <div className='benefits'>
              {features.map((feature) => (
                <div className='global-card flex flex-center-aligned'>
                  <div className='benefits-info'>
                    <p style={{ fontSize: '50px', marginRight: '20px' }}>
                      {feature.icon}
                    </p>
                    <p className='small-title'>{feature.title}</p>
                    <p className='subtitle'>{feature.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
            <Button
              className='landing-button black'
              onClick={() =>
                navigate('/', { state: { scrollTo: '#home-section-6' } })
              }
              type='primary'
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
