import {
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_ERROR,
  OPEN_REVIEW_MODAL,
  HANDLE_GLOBAL_MODAL,
  SET_FOOTER_NAVIGATION,
  SET_MOBILE_VIEW,
  HANDLE_DRAWER_MENU,
  SET_MACRONUTRIENT_ANSWERS,
  SAVE_MACRONUTRIENT_ANSWERS,
  SAVE_MACRONUTRIENT_ANSWERS_SUCCESS,
  SAVE_MACRONUTRIENT_ANSWERS_ERROR,
  GET_MACRONUTRIENT_ANSWERS,
  GET_MACRONUTRIENT_ANSWERS_SUCCESS,
  GET_MACRONUTRIENT_ANSWERS_ERROR,
  SAVE_ADVANCED_MODE_MACROS,
  SAVE_ADVANCED_MODE_MACROS_SUCCESS,
  SAVE_ADVANCED_MODE_MACROS_ERROR,
  SET_MEAL_PLAN_ANSWERS,
} from './constants';

export function sendEmail(emailData, successMessage) {
  return {
    type: SEND_EMAIL,
    emailData,
    successMessage,
  };
}

export function sendEmailSuccess(successMessage) {
  return {
    type: SEND_EMAIL_SUCCESS,
    successMessage,
  };
}

export function sendEmailError(err) {
  return {
    type: SEND_EMAIL_ERROR,
    err,
  };
}

export function openReviewModal(isOpen, review) {
  return {
    type: OPEN_REVIEW_MODAL,
    isOpen,
    review,
  };
}

export function handleGlobalModal(isGlobalModalOpen, globalModalContent) {
  return {
    type: HANDLE_GLOBAL_MODAL,
    isGlobalModalOpen,
    globalModalContent,
  };
}

export function setFooterNavigation(footerNavigation) {
  return {
    type: SET_FOOTER_NAVIGATION,
    footerNavigation,
  };
}

export function setMobileView(isMobileView) {
  return {
    type: SET_MOBILE_VIEW,
    isMobileView,
  };
}

export function handleDrawerMenu(isDrawerOpen) {
  return {
    type: HANDLE_DRAWER_MENU,
    isDrawerOpen,
  };
}

export function setMacronutrientAnswers(macronutrientAnswers) {
  return {
    type: SET_MACRONUTRIENT_ANSWERS,
    macronutrientAnswers,
  };
}

export function saveMacronutrientAnswers(macronutrientAnswers) {
  return {
    type: SAVE_MACRONUTRIENT_ANSWERS,
    macronutrientAnswers,
  };
}

export function saveMacronutrientAnswersSuccess(payload) {
  return {
    type: SAVE_MACRONUTRIENT_ANSWERS_SUCCESS,
    payload,
  };
}

export function saveMacronutrientAnswersError(error) {
  return {
    type: SAVE_MACRONUTRIENT_ANSWERS_ERROR,
    error,
  };
}

export function getMacronutrientAnswers(macronutrientAnswers) {
  return {
    type: GET_MACRONUTRIENT_ANSWERS,
    macronutrientAnswers,
  };
}

export function getMacronutrientAnswersSuccess(payload) {
  return {
    type: GET_MACRONUTRIENT_ANSWERS_SUCCESS,
    payload,
  };
}

export function getMacronutrientAnswersError(error) {
  return {
    type: GET_MACRONUTRIENT_ANSWERS_ERROR,
    error,
  };
}

export function saveAdvancedModeMacros(macros) {
  return {
    type: SAVE_ADVANCED_MODE_MACROS,
    macros,
  };
}

export function saveAdvancedModeMacrosSuccess(payload) {
  return {
    type: SAVE_ADVANCED_MODE_MACROS_SUCCESS,
    payload,
  };
}

export function saveAdvancedModeMacrosError(macros) {
  return {
    type: SAVE_ADVANCED_MODE_MACROS_ERROR,
    macros,
  };
}

export function setMealPlanAnswers(mealPlanAnswers) {
  return {
    type: SET_MEAL_PLAN_ANSWERS,
    mealPlanAnswers,
  };
}
