import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import {
  PiNumberOneBold,
  PiNumberTwoBold,
  PiNumberThreeBold,
  PiNumberFourBold,
  PiNumberFiveBold,
} from 'react-icons/pi';
import { FaStar } from 'react-icons/fa';
import 'react-vertical-timeline-component/style.min.css';

export const Timeline = () => (
  <VerticalTimeline lineColor='#f3f8ff'>
    <VerticalTimelineElement
      animate
      className='vertical-timeline-element'
      contentArrowStyle={{ borderRight: '7px solid black' }}
      iconStyle={{ background: 'black', color: '#fff' }}
      icon={<PiNumberOneBold />}
    >
      <h3 className='small-title'>Sign Up</h3>
      {/* <h4 className='vertical-timeline-element-subtitle'>Miami, FL</h4> */}
      <p style={{ fontSize: '18px', fontWeight: '400' }}>
        Choose from 1-on-1 onsite coaching, 1-on-1 online coaching, or
        self-guided programs.
      </p>
    </VerticalTimelineElement>

    <VerticalTimelineElement
      className='vertical-timeline-element'
      contentArrowStyle={{ borderRight: '7px solid black' }}
      iconStyle={{ background: 'black', color: '#fff' }}
      icon={<PiNumberTwoBold />}
    >
      <h3 className='small-title'>Complete The Assessment</h3>
      {/* <h4 className='vertical-timeline-element-subtitle'>San Francisco, CA</h4> */}
      <p style={{ fontSize: '18px', fontWeight: '400' }}>
        We'll audit your current lifestyle to come up with the perfect plan.
      </p>
    </VerticalTimelineElement>

    <VerticalTimelineElement
      className='vertical-timeline-element'
      contentArrowStyle={{ borderRight: '7px solid black' }}
      iconStyle={{ background: 'black', color: '#fff' }}
      icon={<PiNumberThreeBold />}
    >
      <h3 className='small-title'>Start Your Program</h3>
      {/* <h4 className='vertical-timeline-element-subtitle'>Certification</h4> */}
      <p style={{ fontSize: '18px', fontWeight: '400' }}>
        Your transformation starts. Be ready to improve your energy, mood, and
        sleep.
      </p>
    </VerticalTimelineElement>

    <VerticalTimelineElement
      className='vertical-timeline-element'
      contentArrowStyle={{ borderRight: '7px solid black' }}
      iconStyle={{ background: 'black', color: '#fff' }}
      icon={<PiNumberFourBold />}
    >
      <h3 className='small-title'>Adjust</h3>
      {/* <h4 className='vertical-timeline-element-subtitle'>Bachelor Degree</h4> */}
      <p style={{ fontSize: '18px', fontWeight: '400' }}>
        We adjust your program to match your progress and to avoid plateaus.
      </p>
    </VerticalTimelineElement>

    <VerticalTimelineElement
      className='vertical-timeline-element'
      contentArrowStyle={{ borderRight: '7px solid black' }}
      iconStyle={{ background: 'black', color: '#fff' }}
      icon={<PiNumberFiveBold />}
    >
      <h3 className='small-title'>Adjust & Evolve</h3>
      <p className='timeline-text'>
        We continuously monitor your progress and adjust your plan to ensure you
        keep advancing and overcoming plateaus.
      </p>
    </VerticalTimelineElement>

    <VerticalTimelineElement
      contentArrowStyle={{ borderRight: '7px solid black' }}
      iconStyle={{ background: '#2761f1', color: '#fff' }}
      icon={<FaStar />}
    >
      <h3 className='small-title'>Transformation Achieved</h3>
      <p className='timeline-text'>
        Celebrate your transformation. Enjoy a complete change in your health
        and well-being, and embrace your new lifestyle.
      </p>
    </VerticalTimelineElement>
  </VerticalTimeline>
);
