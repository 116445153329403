export const proteins = [
  {
    name: 'Chicken Breast',
    img: '/assets/foodImages/chicken-breast.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 7,
      carbs: 0,
      fats: 0.75,
      calories: 35,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Sweet Potato', 'Brown Rice', 'Quinoa'],
      fats: ['Olive Oil', 'Avocado', 'Grass-Fed Butter'],
    },
  },
  {
    name: 'Lean Ground Turkey',
    img: '/assets/foodImages/plate.png',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 7,
      carbs: 0,
      fats: 1,
      calories: 37.5,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Brown Rice', 'Quinoa', 'Legumes', 'Whole Wheat Bread'],
      fats: ['Olive Oil', 'Avocado', 'Pesto Sauce'],
    },
  },
  {
    name: 'Lean Ground Beef',
    img: '/assets/foodImages/plate.png',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6,
      carbs: 0,
      fats: 2.5,
      calories: 50,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Sweet Potato', 'Brown Rice', 'Couscous'],
      fats: ['Pesto Sauce', 'Olive Oil', 'Avocado'],
    },
  },
  {
    name: 'Ground Bison',
    img: '/assets/foodImages/plate.png',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6,
      carbs: 0,
      fats: 1.75,
      calories: 41.5,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Sweet Potato', 'Brown Rice', 'Whole Wheat Bread'],
      fats: ['Avocado', 'Pesto Sauce', 'Olive Oil'],
    },
  },
  {
    name: 'Lean Steak',
    img: '/assets/foodImages/steak.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6.25,
      carbs: 0,
      fats: 2,
      calories: 45,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Quinoa', 'Sweet Potato', 'Brown Rice'],
      fats: ['Olive Oil', 'Avocado', 'Grass-Fed Butter'],
    },
  },
  {
    name: 'Salmon',
    img: '/assets/foodImages/salmon.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6.25,
      carbs: 0,
      fats: 3.25,
      calories: 50,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Brown Rice', 'Quinoa', 'Sweet Potato'],
      fats: ['Avocado', 'Grass-Fed Butter', 'Olive Oil'],
    },
  },
  {
    name: 'Shrimp',
    img: '/assets/foodImages/shrimp.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6,
      carbs: 0,
      fats: 0.25,
      calories: 25,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Quinoa', 'Brown Rice', 'Sweet Potato'],
      fats: ['Pesto Sauce', 'Olive Oil', 'Avocado'],
    },
  },
  {
    name: 'Tofu',
    img: '/assets/foodImages/tofu.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 2.25,
      carbs: 0.5,
      fats: 1.25,
      calories: 20,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['vegetarian'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Brown Rice', 'Quinoa', 'Whole Wheat Pasta'],
      fats: ['Avocado', 'Olive Oil', 'Pesto Sauce'],
    },
  },
  {
    name: 'Greek Yogurt',
    img: '/assets/foodImages/greek-yogurt.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 2.5,
      carbs: 1,
      fats: 0,
      calories: 12.5,
    },
    recommendedFor: ['breakfast', 'snack'],
    dietType: ['standard', 'vegetarian'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Mixed Berries', 'Banana', 'Oats', 'Granola'],
      fats: ['Chia Seeds', 'Almonds', 'Nut Butter'],
    },
  },
  {
    name: 'Cottage Cheese',
    img: '/assets/foodImages/plate.png',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 3.5,
      carbs: 1.25,
      fats: 0.5,
      calories: 22.5,
    },
    recommendedFor: ['snack'],
    dietType: ['standard', 'vegetarian'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Rice Cakes', 'Mixed Berries', 'Banana', 'Granola'], // Valid for snack
      fats: ['Nut Butter', 'Chia Seeds', 'Pistachios'], // Valid for snack
    },
  },
  {
    name: 'Eggs',
    img: '/assets/foodImages/egg.jpg',
    servingSize: 1,
    servingType: 'unit',
    macros: {
      protein: 6,
      carbs: 0,
      fats: 5,
      calories: 70,
    },
    recommendedFor: ['breakfast', 'lunch'],
    dietType: ['standard', 'vegetarian'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Oats', 'Brown Rice', 'Sweet Potato'],
      fats: ['Avocado', 'Grass-Fed Butter', 'Pesto Sauce'],
    },
  },
  {
    name: 'Egg Whites',
    img: '/assets/foodImages/egg-whites.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 3.5,
      carbs: 0,
      fats: 0,
      calories: 16.5,
    },
    recommendedFor: ['breakfast'],
    dietType: ['standard', 'vegetarian'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Oats', 'Apple', 'Banana'], // Valid for breakfast
      fats: ['Avocado', 'Chia Seeds', 'Almonds'], // Valid for breakfast
    },
  },
  {
    name: 'Turkey Bacon',
    img: '/assets/foodImages/turkey-bacon.jpg',
    servingSize: 1,
    servingType: 'unit',
    macros: {
      protein: 6,
      carbs: 0,
      fats: 2,
      calories: 40,
    },
    recommendedFor: ['breakfast'],
    dietType: ['standard'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Oats', 'Banana', 'Apple'],
      fats: ['Avocado', 'Grass-Fed Butter', 'Chia Seeds'],
    },
  },
  {
    name: 'Tempeh',
    img: '/assets/foodImages/tempeh.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 5.25,
      carbs: 3.25,
      fats: 2.75,
      calories: 50,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['vegetarian'],
    category: 'protein',
    pairsWellWith: {
      carbs: [
        'Brown Rice',
        'Quinoa',
        'Legumes (lentils, black beans, chickpeas, and similar)',
      ], // Valid for lunch and dinner
      fats: ['Avocado', 'Pesto Sauce', 'Olive Oil'], // Valid for lunch and dinner
    },
  },
  {
    name: 'Seitan',
    img: '/assets/foodImages/plate.png',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6.25,
      carbs: 1,
      fats: 0.5,
      calories: 35,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['vegetarian'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Sweet Potato', 'Brown Rice', 'Couscous'], // Valid for lunch and dinner
      fats: ['Olive Oil', 'Avocado', 'Grass-Fed Butter'], // Valid for lunch and dinner
    },
  },
  {
    name: 'Whey Protein Isolate',
    img: '/assets/foodImages/whey-protein.jpg',
    servingSize: 1,
    servingType: 'scoop (30g)',
    macros: {
      protein: 25,
      carbs: 2,
      fats: 1,
      calories: 120,
    },
    recommendedFor: ['breakfast', 'snack', 'post-workout'],
    dietType: ['standard'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Banana', 'Oats', 'Mixed Berries', 'Granola'], // Valid for breakfast, snack, and post-workout
      fats: ['Chia Seeds', 'Nut Butter', 'Almonds'], // Valid for breakfast, snack, and post-workout
    },
  },
  {
    name: 'Casein Protein',
    img: '/assets/foodImages/whey-protein.jpg',
    servingSize: 1,
    servingType: 'scoop',
    macros: {
      protein: 24,
      carbs: 3,
      fats: 1,
      calories: 110,
    },
    recommendedFor: ['snack', 'pre-bed'],
    dietType: ['standard'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Rice Cakes', 'Banana', 'Mixed Berries'], // Valid for snack and pre-bed
      fats: ['Nut Butter', 'Chia Seeds', 'Almonds'], // Valid for snack and pre-bed
    },
  },
  {
    name: 'Pea Protein',
    img: '/assets/foodImages/whey-protein.jpg',
    servingSize: 1,
    servingType: 'scoop',
    macros: {
      protein: 24,
      carbs: 3,
      fats: 2,
      calories: 120,
    },
    recommendedFor: ['breakfast', 'snack', 'post-workout'],
    dietType: ['vegetarian'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Banana', 'Oats', 'Mixed Berries'], // Valid for breakfast, snack, and post-workout
      fats: ['Chia Seeds', 'Nut Butter', 'Almonds'], // Valid for breakfast, snack, and post-workout
    },
  },
  {
    name: 'Veggie Burger Patty',
    img: '/assets/foodImages/plate.png',
    servingSize: 1,
    servingType: 'patty',
    macros: {
      protein: 10,
      carbs: 10,
      fats: 5,
      calories: 120,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['vegetarian', 'vegan'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Brown Rice', 'Sweet Potato', 'Quinoa'], // Valid for lunch and dinner
      fats: ['Avocado', 'Pesto Sauce', 'Olive Oil'], // Valid for lunch and dinner
    },
  },
  {
    name: 'Canned Tuna',
    img: '/assets/foodImages/tuna.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6.5,
      carbs: 0,
      fats: 0.2,
      calories: 30,
    },
    recommendedFor: ['lunch', 'dinner', 'snack'],
    dietType: ['standard'],
    category: 'protein',
    pairsWellWith: {
      carbs: ['Rice Cakes', 'Sweet Potato', 'Brown Rice'], // Valid for lunch, dinner, and snack
      fats: ['Olive Oil', 'Avocado', 'Pistachios'], // Valid for lunch, dinner, and snack
    },
  },
];

export const carbs = [
  {
    name: 'Sweet Potato',
    img: '/assets/foodImages/sweet-potato.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.5,
      carbs: 6.5,
      fats: 0,
      calories: 26,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'carbs',
    pairsWellWith: {
      protein: ['Chicken Breast', 'Lean Ground Turkey', 'Salmon'],
      fats: ['Avocado', 'Olive Oil', 'Pesto Sauce'],
    },
  },
  {
    name: 'Rice',
    img: '/assets/foodImages/rice.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 1,
      carbs: 7,
      fats: 0.2,
      calories: 32,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'carbs',
    pairsWellWith: {
      protein: ['Ground Bison', 'Lean Steak', 'Shrimp'],
      fats: ['Grass-Fed Butter', 'Olive Oil', 'Avocado'],
    },
  },
  {
    name: 'Quinoa',
    img: '/assets/foodImages/quinoa.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 1.25,
      carbs: 6,
      fats: 0.5,
      calories: 28,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'carbs',
    pairsWellWith: {
      protein: ['Tempeh', 'Seitan', 'Canned Tuna'],
      fats: ['Pesto Sauce', 'Olive Oil', 'Chia Seeds'],
    },
  },
  {
    name: 'Granola',
    img: '/assets/foodImages/granola.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 3,
      carbs: 21.5,
      fats: 1.25,
      calories: 110,
    },
    recommendedFor: ['breakfast', 'snack'],
    dietType: ['standard', 'vegetarian'],
    category: 'carbs',
    pairsWellWith: {
      protein: [
        'Eggs',
        'Greek Yogurt',
        'Cottage Cheese',
        'Whey Protein Isolate',
      ],
      fats: ['Nut Butter', 'Chia Seeds', 'Almonds'],
    },
  },
  {
    name: 'Oats',
    img: '/assets/foodImages/oats.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 3.33,
      carbs: 18,
      fats: 1.67,
      calories: 100,
    },
    recommendedFor: ['breakfast'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'carbs',
    pairsWellWith: {
      protein: ['Egg Whites', 'Greek Yogurt', 'Whey Protein Isolate'],
      fats: ['Nut Butter', 'Chia Seeds', 'Avocado'],
    },
  },
  {
    name: 'Whole Wheat Pasta',
    img: '/assets/foodImages/pasta.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 3.5,
      carbs: 20,
      fats: 0.8,
      calories: 99,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'carbs',
    pairsWellWith: {
      protein: ['Chicken Breast', 'Lean Steak', 'Tofu', 'Veggie Burger Patty'],
      fats: ['Pesto Sauce', 'Olive Oil', 'Grass-Fed Butter'],
    },
  },
  {
    name: 'Rice Cakes',
    img: '/assets/foodImages/rice-cake.jpg',
    servingSize: 1,
    servingType: 'unit',
    macros: {
      protein: 0.7,
      carbs: 7.3,
      fats: 0.2,
      calories: 35,
    },
    recommendedFor: ['snack', 'breakfast'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'carbs',
    pairsWellWith: {
      protein: ['Greek Yogurt', 'Whey Protein Isolate', 'Casein Protein'],
      fats: ['Nut Butter', 'Chia Seeds', 'Pistachios'],
    },
  },
  {
    name: 'Baked Potatoes',
    img: '/assets/foodImages/potatoes.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.54,
      carbs: 6.07,
      fats: 0.033,
      calories: 26.37,
    },
    recommendedFor: ['breakfast', 'lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'carbs',
    pairsWellWith: {
      protein: ['Chicken Breast', 'Eggs', 'Shrimp', 'Tempeh'],
      fats: ['Olive Oil', 'Grass-Fed Butter', 'Avocado'],
    },
  },
  {
    name: 'Couscous',
    img: '/assets/foodImages/plate.png',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 1.25,
      carbs: 6.5,
      fats: 0.1,
      calories: 30,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'carbs',
    pairsWellWith: {
      protein: ['Ground Bison', 'Seitan', 'Salmon'],
      fats: ['Pesto Sauce', 'Olive Oil', 'Avocado'],
    },
  },
  {
    name: 'Whole Wheat Bread',
    img: '/assets/foodImages/bread.jpg',
    servingSize: 1,
    servingType: 'slice',
    macros: {
      protein: 3,
      carbs: 12,
      fats: 1,
      calories: 70,
    },
    recommendedFor: ['breakfast'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'carbs',
    pairsWellWith: {
      protein: [
        'Eggs',
        'Greek Yogurt',
        'Whey Protein Isolate',
        'Whole Wheat Bread',
      ],
      fats: ['Nut Butter', 'Avocado', 'Chia Seeds'],
    },
  },
  {
    name: 'Cream of Rice (Dry)',
    img: '/assets/foodImages/plate.png',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.9,
      carbs: 22.3,
      fats: 0.1,
      calories: 103,
    },
    recommendedFor: ['breakfast'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'carbs',
    pairsWellWith: {
      protein: ['Egg Whites', 'Greek Yogurt', 'Whey Protein Isolate'],
      fats: ['Nut Butter', 'Chia Seeds', 'Avocado'],
    },
  },
  {
    name: 'Apple',
    img: '/assets/foodImages/apple.jpg',
    servingSize: 1,
    servingType: 'unit',
    macros: {
      protein: 0.5,
      carbs: 25,
      fats: 0.3,
      calories: 95,
    },
    recommendedFor: ['snack', 'breakfast'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'carbs',
    pairsWellWith: {
      protein: ['Greek Yogurt', 'Pea Protein', 'Cottage Cheese'],
      fats: ['Almonds', 'Nut Butter', 'Pistachios'],
    },
  },
  {
    name: 'Medium Size Wrap/Tortilla',
    img: '/assets/foodImages/tortilla.jpg',
    servingSize: 1,
    servingType: 'unit',
    macros: {
      protein: 5,
      carbs: 15,
      fats: 3,
      calories: 110,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'carbs',
    pairsWellWith: {
      protein: ['Chicken Breast', 'Ground Bison', 'Veggie Burger Patty'],
      fats: ['Pesto Sauce', 'Avocado', 'Olive Oil'],
    },
  },
  {
    name: 'Mixed Berries',
    img: '/assets/foodImages/mixed-berries.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.2,
      carbs: 2.8,
      fats: 0.1,
      calories: 12.8,
    },
    recommendedFor: ['breakfast', 'snack'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'carbs',
    pairsWellWith: {
      protein: ['Greek Yogurt', 'Whey Protein Isolate', 'Casein Protein'],
      fats: ['Almonds', 'Nut Butter', 'Chia Seeds'],
    },
  },
  {
    name: 'Banana',
    img: '/assets/foodImages/banana.jpg',
    servingSize: 1,
    servingType: 'unit',
    macros: {
      protein: 1.3,
      carbs: 27,
      fats: 0.3,
      calories: 105,
    },
    recommendedFor: ['snack', 'breakfast'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'carbs',
    pairsWellWith: {
      protein: ['Greek Yogurt', 'Whey Protein Isolate', 'Pea Protein'],
      fats: ['Almonds', 'Nut Butter', 'Chia Seeds'],
    },
  },
];

export const fats = [
  {
    name: 'Olive Oil',
    img: '/assets/foodImages/olive-oil.jpg',
    servingSize: 1,
    servingType: 'tbsp',
    macros: {
      protein: 0,
      carbs: 0,
      fats: 14,
      calories: 120,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'fats',
    pairsWellWith: {
      protein: ['Chicken Breast', 'Salmon', 'Tofu'],
      carbs: ['Quinoa', 'Sweet Potato', 'Brown Rice'],
    },
  },
  {
    name: 'Avocado',
    img: '/assets/foodImages/avocado.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.5,
      carbs: 3,
      fats: 4.5,
      calories: 45,
    },
    recommendedFor: ['breakfast', 'lunch', 'snack', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'fats',
    pairsWellWith: {
      protein: ['Eggs', 'Chicken Breast', 'Cottage Cheese'],
      carbs: [
        'Whole Wheat Bread',
        'Baked Potatoes',
        'Legumes',
        'Rice',
        'Sweet Potato',
      ],
    },
  },
  {
    name: 'Almonds',
    img: '/assets/foodImages/almonds.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6,
      carbs: 6,
      fats: 14,
      calories: 160,
    },
    recommendedFor: ['snack'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'fats',
    pairsWellWith: {
      protein: ['Greek Yogurt', 'Whey Protein Isolate', 'Pea Protein'],
      carbs: ['Apple', 'Banana', 'Mixed Berries'],
    },
  },
  {
    name: 'Walnuts',
    img: '/assets/foodImages/walnuts.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 4.3,
      carbs: 4,
      fats: 18,
      calories: 185,
    },
    recommendedFor: ['snack'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'fats',
    pairsWellWith: {
      protein: ['Greek Yogurt', 'Whey Protein Isolate', 'Casein Protein'],
      carbs: ['Apple', 'Banana', 'Rice Cakes'],
    },
  },
  {
    name: 'Nut Butter',
    img: '/assets/foodImages/nut-butter.jpg',
    servingSize: 1,
    servingType: 'tbsp',
    macros: {
      protein: 3.5,
      carbs: 1.5,
      fats: 8,
      calories: 94,
    },
    recommendedFor: ['breakfast', 'snack'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'fats',
    pairsWellWith: {
      protein: ['Egg Whites', 'Greek Yogurt', 'Whey Protein Isolate'],
      carbs: ['Whole Wheat Bread', 'Banana', 'Oats'],
    },
  },
  {
    name: 'Grass-Fed Butter',
    img: '/assets/foodImages/butter.jpg',
    servingSize: 1,
    servingType: 'tbsp',
    macros: {
      protein: 0,
      carbs: 0,
      fats: 11,
      calories: 100,
    },
    recommendedFor: ['breakfast', 'lunch', 'dinner'],
    dietType: ['standard', 'vegetarian'],
    category: 'fats',
    pairsWellWith: {
      protein: ['Eggs', 'Lean Steak', 'Tempeh'],
      carbs: ['Cream of Rice (Dry)', 'Couscous', 'Quinoa'],
    },
  },
  {
    name: 'Chia Seeds',
    img: '/assets/foodImages/chia-seeds.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 5,
      carbs: 12,
      fats: 9,
      calories: 137,
    },
    recommendedFor: ['breakfast', 'snack'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'fats',
    pairsWellWith: {
      protein: ['Greek Yogurt', 'Egg Whites', 'Whey Protein Isolate'],
      carbs: ['Mixed Berries', 'Oats', 'Banana'],
    },
  },
  {
    name: 'Cashews',
    img: '/assets/foodImages/cashews.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 5,
      carbs: 9,
      fats: 12,
      calories: 155,
    },
    recommendedFor: ['snack'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'fats',
    pairsWellWith: {
      protein: ['Cottage Cheese', 'Greek Yogurt', 'Pea Protein'],
      carbs: ['Apple', 'Mixed Berries', 'Rice Cakes'],
    },
  },
  {
    name: 'Coconut Oil',
    img: '/assets/foodImages/coconut-oil.jpg',
    servingSize: 1,
    servingType: 'tbsp',
    macros: {
      protein: 0,
      carbs: 0,
      fats: 7,
      calories: 60,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'fats',
    pairsWellWith: {
      protein: ['Shrimp', 'Ground Bison', 'Seitan'],
      carbs: ['Sweet Potato', 'Brown Rice', 'Whole Wheat Pasta'],
    },
  },
  {
    name: 'Pistachios',
    img: '/assets/foodImages/pistachios.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6,
      carbs: 8,
      fats: 12,
      calories: 160,
    },
    recommendedFor: ['snack'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'fats',
    pairsWellWith: {
      protein: ['Whey Protein Isolate', 'Cottage Cheese', 'Greek Yogurt'],
      carbs: ['Apple', 'Rice Cakes', 'Banana'],
    },
  },
  {
    name: 'Dark Chocolate (85%)',
    img: '/assets/foodImages/dark-chocolate.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 2,
      carbs: 13,
      fats: 12,
      calories: 170,
    },
    recommendedFor: ['snack'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'fats',
    pairsWellWith: {
      protein: ['Casein Protein', 'Cottage Cheese', 'Greek Yogurt'],
      carbs: ['Banana', 'Apple', 'Mixed Berries'],
    },
  },
  {
    name: 'Macadamia Nuts',
    img: '/assets/foodImages/macademia-nuts.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 2,
      carbs: 4,
      fats: 21,
      calories: 200,
    },
    recommendedFor: ['snack'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'fats',
    pairsWellWith: {
      protein: ['Whey Protein Isolate', 'Cottage Cheese', 'Greek Yogurt'],
      carbs: ['Banana', 'Rice Cakes', 'Apple'],
    },
  },
  {
    name: 'Pesto Sauce',
    img: '/assets/foodImages/pesto-sauce.jpg',
    servingSize: 1,
    servingType: 'tbsp',
    macros: {
      protein: 0.5,
      carbs: 1,
      fats: 9,
      calories: 90,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian'],
    category: 'fats',
    pairsWellWith: {
      protein: ['Chicken Breast', 'Ground Turkey', 'Tempeh'],
      carbs: ['Whole Wheat Pasta', 'Sweet Potato', 'Couscous'],
    },
  },
];

export const greenVegetables = [
  {
    name: 'Spinach',
    img: '/assets/foodImages/green-vegetables.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 1,
      carbs: 1,
      fats: 0,
      calories: 7,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'greenVegetables',
  },
  {
    name: 'Kale',
    img: '/assets/foodImages/green-vegetables.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.9,
      carbs: 1.4,
      fats: 0.1,
      calories: 9,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'greenVegetables',
  },
  {
    name: 'Broccoli',
    img: '/assets/foodImages/green-vegetables.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 1,
      carbs: 2,
      fats: 0,
      calories: 10,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'greenVegetables',
  },
  {
    name: 'Green Beans',
    img: '/assets/foodImages/green-vegetables.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.8,
      carbs: 2.7,
      fats: 0,
      calories: 10,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'greenVegetables',
  },
  {
    name: 'Zucchini',
    img: '/assets/foodImages/green-vegetables.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.4,
      carbs: 1.2,
      fats: 0,
      calories: 5,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'greenVegetables',
  },
  {
    name: 'Cucumber',
    img: '/assets/foodImages/green-vegetables.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.3,
      carbs: 1.1,
      fats: 0,
      calories: 4,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'greenVegetables',
  },
  {
    name: 'Asparagus',
    img: '/assets/foodImages/green-vegetables.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.9,
      carbs: 1.3,
      fats: 0,
      calories: 6,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'greenVegetables',
  },
  {
    name: 'Brussels Sprouts',
    img: '/assets/foodImages/green-vegetables.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 1.3,
      carbs: 2.5,
      fats: 0.1,
      calories: 11,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'greenVegetables',
  },
  {
    name: 'Romaine Lettuce',
    img: '/assets/foodImages/green-vegetables.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.5,
      carbs: 1,
      fats: 0,
      calories: 5,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'greenVegetables',
  },
  {
    name: 'Collard Greens',
    img: '/assets/foodImages/green-vegetables.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.9,
      carbs: 2,
      fats: 0.2,
      calories: 11,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'greenVegetables',
  },
  {
    name: 'Swiss Chard',
    img: '/assets/foodImages/green-vegetables.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.7,
      carbs: 1.3,
      fats: 0,
      calories: 7,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'greenVegetables',
  },
  {
    name: 'Arugula',
    img: '/assets/foodImages/green-vegetables.jpg',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.3,
      carbs: 0.6,
      fats: 0,
      calories: 5,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
    category: 'greenVegetables',
  },
];
