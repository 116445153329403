import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Input, Button, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../ducks/Auth/actions';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import AlvaroFitness from '../assets/images/af-logo-white.png';

export const Login = ({ isModal, isLogin }) => {
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formFields, setFormFields] = useState({ email: '', password: '' });
  const auth = useSelector((state) => state.auth);
  const serverErrors = useSelector((state) => state.auth.errors);
  const navigate = useNavigate();
  const location = useLocation();

  const onChange = (val, e) => {
    setFormFields({ ...formFields, [val]: e.target.value });
  };

  useEffect(() => {
    if (auth.isAuthenticated && auth.user) {
      if (auth.user.role === 'admin') {
        navigate('/meals/orders');
      } else if (location.pathname.includes('macronutrient-calculator')) {
        dispatch({ type: 'OPEN_AUTH_MODAL', payload: false }); //close modal when logging in from /macronutrient-calculator
      } else {
        window.location.href = '/tools/macronutrient-calculator';
      }
    }
  }, [auth.isAuthenticated, auth.user, location.pathname, dispatch, navigate]);

  return (
    <div
      className={`auth-page ${isModal ? 'modal' : ''}`}
      style={{ height: isLogin && !isModal ? '100vh' : '' }}
    >
      <div
        className={`auth-container ${isModal ? 'modal' : ''}`}
        style={{ width: isLogin ? '450px' : '' }}
      >
        <img
          src={AlvaroFitness}
          alt='logo'
          style={{ height: '35px', margin: '0 auto', cursor: 'pointer' }}
          onClick={() => navigate('/')}
        />
        <p className='title'>Login</p>
        <Space direction='vertical'>
          <Input
            className='register-input'
            placeholder='Email address'
            onChange={(e) => onChange('email', e)}
          />
          <Input.Password
            className='register-input'
            onChange={(e) => onChange('password', e)}
            placeholder='Password'
            iconRender={(visible) =>
              visible ? (
                <EyeTwoTone
                  onClick={() => setPasswordVisible(!passwordVisible)}
                />
              ) : (
                <EyeInvisibleOutlined
                  onClick={() => setPasswordVisible(!passwordVisible)}
                />
              )
            }
            maxLength={20}
          />
          <Link
            onClick={() =>
              isModal && dispatch({ type: 'OPEN_AUTH_MODAL', payload: false })
            }
            style={{ fontSize: '16px' }}
            to='/reset-password'
          >
            Forgot password?
          </Link>
          <Button
            style={{ borderRadius: '5px' }}
            className='landing-button gradient full-width-button'
            onClick={() => dispatch(loginUser(formFields))}
            type='primary'
            size='large'
          >
            Login
          </Button>
          <div className='flex center-aligned justify-center mt-10'>
            <p style={{ fontSize: '16px' }} className='mr-5'>
              Don't have an account?
            </p>
            <Link
              onClick={() => {
                dispatch({ type: 'CLEAR_AUTH_ERROR' });
                dispatch({ type: 'SET_IS_LOGIN', payload: !isLogin });
              }}
              style={{ fontSize: '16px' }}
              to={!isModal && '/register'}
            >
              Sign up
            </Link>
          </div>
          {serverErrors &&
            serverErrors.map((err, index) => (
              <p
                key={index}
                className='text-center'
                style={{ color: 'red', marginTop: '10px' }}
              >
                {err.msg}
              </p>
            ))}
        </Space>
      </div>
    </div>
  );
};
