import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import SupplementPlan from '../../assets/images/supplement-plan.svg';
import NutritionPlan from '../../assets/images/nutrition-plan.svg';
import WorkoutPlan from '../../assets/images/workout-plan.svg';

export const SpecialOfferModal = ({ style, sectionsRefs, handleScroll }) => {
  const dispatch = useDispatch();
  const isSpecialModalOpen = useSelector(
    (state) => state.global.isSpecialModalOpen
  );

  return (
    <div style={style}>
      <Modal
        open={isSpecialModalOpen}
        onCancel={() =>
          dispatch({ type: 'HANDLE_SPECIAL_OFFER_MODAL', payload: false })
        }
        footer={null}
        centered
      >
        <div className=' justify-center'>
          <div className='text-center grid align-center'>
            <h1 className='mb-20' style={{ color: '#2a3e50' }}>
              🚀 Limited-Time March Deal for New Clients!
            </h1>
            <p className='subtitle mb-20'>
              In 12 weeks, unlock a stronger, more confident version of
              yourself. Build muscle, shed fat, and feel better than ever—inside
              and out!
            </p>
            <div className='grid'>
              <div className='mb-20'>
                <span
                  style={{
                    padding: '10px 15px',
                    background: 'rgb(243, 248, 255)',
                    borderRadius: '50px',
                  }}
                >
                  1 out of 3 available
                </span>
              </div>
              <div className='mb-30'>
                <p className='small-title text-gradient'>$900/mo</p>
                <p style={{ fontSize: '14px' }}>3 month commitement</p>
              </div>
            </div>
          </div>
          <div className='mb-30'>
            <div className='grid justify-center mb-30'>
              <img
                className='mb-10'
                style={{ height: '50px' }}
                alt='workout plan'
                src={WorkoutPlan}
              />
              <p className='text-center'>3 Weekly Training Sessions</p>
            </div>
            <div className='grid justify-center mb-30'>
              <img
                className='mb-10'
                style={{ height: '50px' }}
                alt='nutrition plan'
                src={NutritionPlan}
              />
              <p className='text-center'>
                Personalized Nutrition Plan And Updates
              </p>
            </div>
            <div className='grid justify-center mb-30'>
              <img
                className='mb-10'
                style={{ height: '50px' }}
                alt='supplement protocol'
                src={SupplementPlan}
              />
              <p className='text-center'>Personalized Supplement Protocol</p>
            </div>
          </div>
          <div className='flex justify-center'>
            <Button
              className='text-center'
              type='primary'
              onClick={() => {
                handleScroll(sectionsRefs['#home-section-8']);
                dispatch({
                  type: 'HANDLE_SPECIAL_OFFER_MODAL',
                  payload: false,
                });
              }}
            >
              Start Your Transformation
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
