import React from 'react';
import { Button } from 'antd';
import { FaCheckCircle } from 'react-icons/fa';
import { starRating } from '../../helpers/utils';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const LandingHeader = (props) => {
  const location = useLocation();

  const {
    color,
    title,
    subtitle,
    btnAction,
    video,
    image,
    btnText,
    bulletPoints,
    textOverTitle,
  } = props;
  const isMobileView = useSelector((state) => state.global.isMobileView);

  const headerLogic = () => {
    if (location.pathname === '/') {
      return { height: 'calc(100vh - 136px)' };
    } else if (video) {
      return { height: 'calc(100vh - 96px)' };
    } else if (!isMobileView) {
      return { height: 'calc(100vh - 96px)' };
    } else {
      return { height: 'auto' };
    }
  };

  return (
    <div className='header mobile' style={headerLogic()}>
      {video && (
        <>
          <video
            className='header-video'
            autoPlay
            muted
            loop
            playsInline
            preload='metadata'
            poster='your-placeholder-image.jpg'
          >
            <source src={video} type='video/mp4' />
            Your browser does not support the video tag.
          </video>

          <div className='overlay'></div>
        </>
      )}
      <div
        className='header-content'
        style={{ alignContent: image ? 'flex-start' : '' }}
      >
        <div
          className={`header-container ${
            !image ? 'align-center' : 'width-for-img'
          }`}
        >
          <div className='header-container-title'>
            {textOverTitle && (
              <p className='eyebrow-text text-gradient bold mb-10'>
                {textOverTitle}
              </p>
            )}
            <p
              className={`title program-title ${!image ? 'text-center' : ''}`}
              style={{ color: video && 'white' }}
            >
              {title}
            </p>
          </div>
          {/* {subtitle && (
            <div style={{ marginTop: '-10px' }}>
              <p
                className='big-subtitle'
                style={{
                  maxWidth: '450px',
                  color: video && 'white',
                  textAlign: video && 'center',
                }}
              >
                {subtitle}
              </p>
            </div>
          )} */}

          {bulletPoints && (
            <div
              style={{
                padding: '20px',
                borderRadius: '20px',
              }}
            >
              <p className='mb-20'>
                Join 100's of Clients Who Have Achieved These Results:
              </p>
              {bulletPoints.map((b) => (
                <div className='grid-bullet-point'>
                  <FaCheckCircle
                    className='mr-10'
                    style={{
                      color: '#2761F1',
                      marginTop: '5px',
                      fontSize: '20px',
                    }}
                  />
                  <p className='subtitle'>{b}</p>
                </div>
              ))}
            </div>
          )}
          <div className={`${!image ? 'grid justify-center' : ''}`}>
            <Button
              className='landing-button black'
              onClick={btnAction}
              type='primary'
              style={{ background: video && 'blue' }}
            >
              {btnText}
            </Button>

            <div className='header-reviews'>
              <div className='flex center-aligned'>
                <div className='flex center-aligned'>
                  <div className='grid justify-center'>
                    <p
                      className='subtitle bold mb-5'
                      style={{
                        color: video && 'white',
                      }}
                    >
                      Trusted by 100's of clients
                    </p>
                    {starRating(5)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {image ? (
          <div className='img'>
            <img src={image} alt={'Alvaro'} loading='lazy' />
          </div>
        ) : null}
      </div>
    </div>
  );
};
