export const REGISTER = 'REGISTER';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

export const REQUEST_RESET_EMAIL = 'REQUEST_RESET_EMAIL';
export const REQUEST_RESET_EMAIL_SUCCESS = 'REQUEST_RESET_EMAIL_SUCCESS';
export const REQUEST_RESET_EMAIL_ERROR = 'REQUEST_RESET_EMAIL_ERROR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const LOAD_USER = 'LOAD_USER';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const LOGOUT = 'LOGOUT';

export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';

export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL';

export const SET_IS_LOGIN = 'SET_IS_LOGIN';
