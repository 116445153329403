import React, { useState } from 'react';
import { Button, Input, Form } from 'antd';
import { FiPhone, FiMail } from 'react-icons/fi';
import { IoLocationOutline } from 'react-icons/io5';
import emailjs from '@emailjs/browser';
import './index.css';

export const ContactForm = () => {
  const [form] = Form.useForm();
  const [status, setStatus] = useState('');

  const sendEmail = (values) => {
    console.log('Form Values:', values);

    emailjs
      .send('service_iwj03hn', 'template_5xd1o3c', values, 'ApIV_6FR_DzaKUtW4')
      .then(
        (result) => {
          setStatus(
            '✅ Your message sent successfully! I will get back to you as soon as possible.'
          );
          console.log('Success:', result.text);
          form.resetFields();
        },
        (error) => {
          setStatus('❌ Something went wrong, please try again.');
          console.log('Error:', error.text);
        }
      );
  };

  return (
    <div className='contact-form-section'>
      <div className='title-section'>
        <div>
          <p className='title mb-5'>Have Questions? Let's Talk.</p>
          <p className='subtitle'>
            If you are ready to get started or have any additional questions,
            please fill out the form and I will get back to you as soon as
            possible.
          </p>
        </div>
        <div>
          <div className='contact-info flex center-aligned mb-20'>
            <div className='icon-container'>
              <FiPhone className='icon' />
            </div>
            <div className='info'>
              <p className='bold' style={{ color: '#2A3E50' }}>
                Phone
              </p>
              <a className='form-subtitle' href='tel:+14786960637'>
                (478) 696-0637
              </a>
            </div>
          </div>
          <div className='contact-info flex center-aligned mb-20'>
            <div className='icon-container'>
              <FiMail className='icon' />
            </div>
            <div className='info'>
              <p className='bold' style={{ color: '#2A3E50' }}>
                Email
              </p>
              <a
                className='form-subtitle'
                href='mailto:contact@alvarofitness.com'
              >
                contact@alvarofitness.com
              </a>
            </div>
          </div>
          <div className='contact-info flex center-aligned mb-20'>
            <div className='icon-container'>
              <IoLocationOutline className='icon' />
            </div>
            <div className='info'>
              <p className='bold' style={{ color: '#2A3E50' }}>
                Address
              </p>
              <a
                className='form-subtitle'
                href='https://www.google.com/maps?q=4101+Bryan+St+%23140+Dallas,+TX+75204'
                target='_blank'
                rel='noreferrer'
              >
                4101 Bryan St #140, Dallas, TX
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className='form'>
        <p className='small-title'>Send me a message</p>
        <Form layout='vertical' form={form} onFinish={sendEmail}>
          <Form.Item
            label='Name'
            name='name'
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input
              className='contact-form-input'
              placeholder='Enter your name'
            />
          </Form.Item>

          <Form.Item
            label='Email'
            name='email'
            rules={[
              { required: true, message: 'Email is required' },
              { type: 'email', message: 'Enter a valid email' },
            ]}
          >
            <Input
              className='contact-form-input'
              placeholder='Enter your email'
            />
          </Form.Item>
          <Form.Item
            label='Phone'
            name='phone'
            rules={[
              { required: true, message: 'Phone # is required' },
              {
                pattern: /^[0-9]{10}$/, // ✅ Allows only 10-digit phone numbers
                message: 'Enter a valid phone number',
              },
            ]}
          >
            <Input
              className='contact-form-input'
              placeholder='Enter your phone #'
            />
          </Form.Item>

          <Form.Item
            label='Message'
            name='message'
            rules={[{ required: true, message: 'Message is required' }]}
          >
            <Input.TextArea
              className='contact-form-text-area'
              style={{ height: '120px' }}
              rows={4}
              placeholder='Enter your message'
            />
          </Form.Item>

          <Button
            className='landing-button black'
            type='primary'
            style={{ width: '100%' }}
            htmlType='submit'
          >
            Submit
          </Button>
          {status && <p className='text-center mt-10'>{status}</p>}
        </Form>
      </div>
    </div>
  );
};
