import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import AlvaroCircle from '../assets/images/AlvaroCircle.png';

export function About() {
  const navigate = useNavigate();

  return (
    <div className='grid justify-center'>
      <div className='content'>
        <div
          className='grid justify-center'
          style={{ width: '100%', maxWidth: '650px' }}
        >
          <img
            className='mb-20'
            style={{ maxHeight: '200px' }}
            src={AlvaroCircle}
            alt='Alvaro'
            loading='lazy'
          />
          <div>
            <p className='small-title bold text-center mb-20'>
              👋 Hi, I'm Alvaro De La Torre...
            </p>
            <p className='subtitle mb-20'>
              And if you're a busy professional or entrepreneur struggling to
              find time to focus on your health and fitness...
            </p>
            <p className='subtitle mb-20'>
              {' '}
              Or if you're feeling frustrated because nothing you've tried has
              worked to help you achieve the body and confidence you want...
            </p>

            <p className='subtitle bold mb-20'>
              Then, I want to let you know you're in the right place.
            </p>
            <p className='subtitle mb-20'>
              What many overlook, including so-called "experts" on social media,
              is the real reason behind unsuccessful fitness and body
              transformations.
            </p>
            <p className='subtitle bold mb-20'>
              {' '}
              It boils down to one of two things (and often both):
            </p>
            <div className='mb-20'>
              <div className='grid-bullet-point'>
                <span className='mr-10'>👉</span>
                <p className='subtitle'>
                  {' '}
                  Not identifying and addressing the root cause of your
                  challenges
                </p>
              </div>
              <div className='grid-bullet-point'>
                <span className='mr-10'>👉</span>
                <p className='subtitle'>
                  {' '}
                  Not following a personalized plan that's tailored to your
                  unique needs and lifestyle
                </p>
              </div>
            </div>
            <p className='subtitle mb-20'>
              Everything you've tried so far has fallen short because it either
              didn't get to the heart of the problem or wasn't the right fit for
              you.
            </p>
            <p className='subtitle bold mb-20'> But today, that changes.</p>
            <p className='subtitle mb-20'>
              {' '}
              With a transformation program tailored just for you, I promise
              you're going to have new hope. Hope that maybe you thought was
              lost forever.
            </p>
            <p className='subtitle bold mb-20'>
              This isn't about quick fixes or cookie-cutter solutions...{' '}
            </p>
            <p className='subtitle mb-20'>
              {' '}
              It's a deep dive into your unique fitness journey and crafting a
              strategy to achieve real results.
            </p>
            <p className='subtitle mb-20'>
              Imagine starting your day with more energy, feeling confident
              about your body, and enjoying the activities you love without any
              stress or fatigue...
            </p>
            <p className='subtitle mb-20'>
              Picture yourself at work or social events, feeling confident and
              proud of your body and the progress you've made...
            </p>
            <p className='subtitle mb-20'>
              Or imagine never having to invent excuses again for avoiding the
              gym or healthy habits...
            </p>
            <p className='subtitle mb-20'>
              This is what happens when you tackle fitness with a plan made just
              for you.
            </p>
            <p className='subtitle mb-20'>
              You don't need another one-size-fits-all solution.
            </p>
            <p className='subtitle mb-20'>You've been there, done that.</p>
            <p className='subtitle mb-20'>
              {' '}
              What you need is a breakthrough that understands the real you –
              and gets you back to feeling your best, confident, and strong.
            </p>
            <p className='subtitle mb-20'>
              {' '}
              It might sound too good to be true, but trust me, it's real.
            </p>
            <p className='subtitle mb-20'>
              {' '}
              My approach is backed by science, years of experience, and
              countless success stories of people who were once in your
              position.
            </p>
            <p className='subtitle mb-20'>
              {' '}
              Are you ready to transform your body, boost your confidence, and
              rediscover the person you are meant to be?
            </p>
            <p className='subtitle'> I look forward to meeting you!</p>
          </div>
        </div>

        <div className='grid justify-center'>
          <Button
            onClick={() =>
              navigate('/', { state: { scrollTo: '#home-section-6' } })
            }
            className='landing-button black mt-50'
            type='primary'
          >
            Get Started
          </Button>
          <p className='text-under-btn text-center mt-5'>
            "He is one of the best personal trainers I’ve ever worked with" -
            Pritesh
          </p>
        </div>
      </div>
    </div>
  );
}
