export const faqData = {
  macronutrientCalculator: [
    {
      key: '1',
      header: (
        <p className='collapse-text'>
          How do I track my daily food intake to ensure I'm meeting my calorie
          and macro goals?
        </p>
      ),
      text: (
        <p className='collapse-text'>
          Tracking daily food intake is a crucial step in achieving fitness
          goals based on calculated calories and macros. One of the most
          effective methods is keeping a food diary or using a mobile app that
          allows users to log their meals and snacks. These tools provide
          insights into the exact amount of calories and macros consumed
          throughout the day. <br />
          <br />
          By diligently recording meals, individuals can identify patterns, make
          adjustments, and stay accountable to their nutrition plan. Moreover,
          food tracking empowers users to understand the impact of their dietary
          choices, making it easier to make informed decisions that align with
          their goals.
        </p>
      ),
    },
    {
      key: '2',
      header: (
        <p className='collapse-text'>
          What are the best food sources for obtaining protein, carbohydrates,
          and healthy fats?
        </p>
      ),
      text: (
        <p className='collapse-text'>
          Knowing the best food sources for each macronutrient is essential for
          maintaining a balanced diet. Protein-rich foods include lean meats,
          poultry, fish, eggs, dairy products, legumes, and plant-based
          alternatives like tofu and tempeh.
          <br />
          <br />
          Carbohydrates can be obtained from whole grains, fruits, vegetables,
          and legumes, offering a variety of essential vitamins and minerals.
          <br />
          <br />
          Healthy fats are found in avocados, nuts, seeds, olive oil, and fatty
          fish, providing essential omega-3 fatty acids. Incorporating a diverse
          range of these food sources ensures an ample supply of nutrients to
          support overall health and optimize performance during workouts.
        </p>
      ),
    },
    {
      key: '3',
      header: (
        <p className='collapse-text'>
          Can I adjust my macronutrient ratios to match my specific dietary
          preferences?
        </p>
      ),
      text: (
        <p className='collapse-text'>
          Absolutely! Macronutrient ratios can be customized to align with
          personal dietary preferences and goals. Some individuals might prefer
          higher protein diets to support muscle building and recovery, while
          others may opt for a balanced approach with equal distribution of
          carbs, protein, and fats. <br />
          <br />
          Flexibility in macronutrient ratios allows for adherence to a diet
          that suits individual tastes and cultural preferences, making it more
          sustainable in the long term. It's essential to experiment and find
          the right balance that satisfies both nutritional needs and personal
          enjoyment of food.
        </p>
      ),
    },
    {
      key: '4',
      header: (
        <p className='collapse-text'>
          What are the potential benefits of following a low-carb or high-carb
          diet?
        </p>
      ),
      text: (
        <p className='collapse-text'>
          Low-carb and high-carb diets offer distinct benefits depending on
          individual fitness goals and preferences. Low-carb diets, such as the
          ketogenic diet, are associated with promoting ketosis, a metabolic
          state where the body burns fat for fuel instead of carbohydrates. This
          can aid in weight loss and improved blood sugar control, making it
          suitable for some individuals with specific health conditions like
          diabetes. <br />
          <br />
          On the other hand, high-carb diets are beneficial for athletes engaged
          in intense physical activities or endurance training, as carbohydrates
          provide a readily available source of energy. The type of diet that
          best suits an individual depends on their body's response to different
          macronutrient ratios and the specific demands of their physical
          activities.
        </p>
      ),
    },
    {
      key: '5',
      header: (
        <p className='collapse-text'>
          Are there any specific dietary considerations for vegetarians or
          vegans to meet their protein needs?
        </p>
      ),
      text: (
        <p className='collapse-text'>
          Vegetarians and vegans can absolutely meet their protein needs through
          plant-based sources. Excellent protein-rich options for vegetarians
          include tofu, tempeh, legumes (beans, lentils, chickpeas), quinoa,
          nuts, and seeds. Vegans can obtain protein from the same sources as
          vegetarians, along with plant-based protein powders and fortified
          foods.
          <br />
          <br />
          It's important for those following plant-based diets to ensure they
          consume a variety of protein sources to get all essential amino acids.
          Additionally, pairing plant-based proteins with foods high in vitamin
          C can enhance iron absorption, an essential nutrient often found in
          lower amounts in plant-based foods.
        </p>
      ),
    },
    {
      key: '6',
      header: (
        <p className='collapse-text'>
          How do I modify my calorie and macro intake if my activity level
          changes?
        </p>
      ),
      text: (
        <p className='collapse-text'>
          Modifying calorie and macro intake based on changes in activity level
          is crucial for maintaining progress towards fitness goals. When
          activity level increases due to more intense workouts or physical
          demands, caloric needs may increase as well.
          <br />
          <br />
          Similarly, if activity level decreases, caloric intake may need to be
          adjusted to prevent weight gain. To determine the appropriate changes,
          you can use the macro calculator as many times as you want to adjust
          your macronutrient ratios accordingly.
          <br />
          <br />
          Regular reassessment of dietary needs and potential tweaks to
          macronutrient intake ensure optimal nutrition for body composition and
          performance.
        </p>
      ),
    },
  ],
  oneRepMaxCalculator: [
    {
      key: '1',
      header: <p className='collapse-text'>What is a One Rep Max (1RM)?</p>,
      text: (
        <p className='collapse-text'>
          A One Rep Max (1RM) is the maximum amount of weight you can lift for a
          particular exercise with proper form, for a single repetition. It's
          often used as a measure of strength and an indicator of progress in
          weightlifting and strength training.
        </p>
      ),
    },
    {
      key: '2',
      header: (
        <p className='collapse-text'>
          Why is Knowing My One Rep Max Important?
        </p>
      ),
      text: (
        <p className='collapse-text'>
          Knowing your One Rep Max helps you determine your current strength
          level and set appropriate training goals. It's used to calculate
          training intensities, plan workout programs, and track your progress
          over time.
        </p>
      ),
    },
    {
      key: '3',
      header: (
        <p className='collapse-text'>How Do I Calculate My One Rep Max?</p>
      ),
      text: (
        <p className='collapse-text'>
          We use the Lombardi formula to calculate your One Rep Max. This
          formula takes into account the weight you lift and the number of
          repetitions you perform. This value provides insight into your maximum
          strength capacity for a specific exercise.
        </p>
      ),
    },
    {
      key: '4',
      header: <p className='collapse-text'>Is Testing My One Rep Max Safe?</p>,
      text: (
        <p className='collapse-text'>
          Testing your One Rep Max can be physically demanding and potentially
          risky, especially if not done with proper technique or under
          supervision. It's recommended to perform 1RM testing under controlled
          conditions, with a spotter or trained professional.
        </p>
      ),
    },
  ],
  programFaq: [
    {
      key: '1',
      header: (
        <p className='collapse-text'>When will I get my plan after purchase?</p>
      ),
      text: (
        <p className='collapse-text'>
          On average, the start-up time is 1-2 business days. After you’ve
          completed the assessment, accessed your dashboard, and completed the
          to-do's, I'll start crafting your personalized plan based on your
          preferences. When your plan is ready, you will receive an email
          confirmation. You should aim to start your plan on Monday. (Starting
          on a Monday works best for most people, but isn’t mandatory if you’d
          like to start on a different day.)
        </p>
      ),
    },
    {
      key: '2',
      header: (
        <p className='collapse-text'>
          Can I make this work on a busy schedule?
        </p>
      ),
      text: (
        <p className='collapse-text'>
          Yes! Waiting for the perfect time could be an ever lasting wait. This
          program is especially tailored to your preferences, so rest assured
          you get amazing personalization. When building your program, I will
          consider everything based on your assessment answers.
        </p>
      ),
    },
    {
      key: '3',
      header: (
        <p className='collapse-text'>How soon will I start seeing results?</p>
      ),
      text: (
        <p className='collapse-text'>
          If you follow the plan consistently you'll be able to notice results
          in as little as 4 weeks. Please keep in mind this varies based on how
          strictly you stick to the program.
        </p>
      ),
    },
    {
      key: '4',
      header: (
        <p className='collapse-text'>What if I travel regularly for work?</p>
      ),
      text: (
        <p className='collapse-text'>
          If you travel regularly, you can add that to your assessment answers.
          I will add some options in your plan when you don't have access to
          equipment.
        </p>
      ),
    },
    {
      key: '5',
      header: (
        <p className='collapse-text'>How does your refund process work?</p>
      ),
      text: (
        <p className='collapse-text'>
          My refund policy is straight forward. If you have logged all your
          workouts on the app for the first month, and you’re unhappy with your
          progress, I’ll refund you in full. However, I am certain that after
          following the program for several weeks, you will be thrilled with the
          results. All I ask is that you give me a chance to make it work.
        </p>
      ),
    },
    {
      key: '6',
      header: (
        <p className='collapse-text'>
          Will there be new features and improvements added to the app after I
          join?
        </p>
      ),
      text: (
        <p className='collapse-text'>
          Yes! I'm dedicated to innovation. I'm continuously working on new
          features and improvements to keep your program fresh and unlike any
          other out there. Stay tuned for regular updates!
        </p>
      ),
    },
  ],
  onsiteProgramFaq: [
    {
      key: '1',
      header: (
        <p className='small-title'>How does your coaching program work?</p>
      ),
      text: (
        <p className='subtitle'>
          In the onsite transformation program, I take a personal and customized
          approach to help you achieve your goals. I understand that you are
          unique, which is why my program is tailored specifically to you.
          <br />
          <br />
          First, I start by getting to know you better. I want to understand
          your fitness history, current levels of fitness, and any personal
          logistics that may impact your training. This allows me to design a
          program that fits seamlessly into your lifestyle.
          <br />
          <br />
          Then, I create an individualized nutrition plan and I tailor your
          workouts based on your goals and provide you with clear direction and
          support as we work together towards your goals.
          <br />
          <br />
          Throughout your program, I provide ongoing support and accountability,
          regularly checking in to track your progress and make adjustments as
          needed.
          <br />
          <br />
          I am committed to your success. I remove the guesswork, giving you
          confidence in every effort you put in.
          <br />
          <br />
          If you're ready to experience a personalized approach to coaching,
          <a
            href='https://calendly.com/alvarofitness/consultation'
            target='_blank'
            rel='noreferrer noopener'
          >
            {' '}
            book a consultation
          </a>{' '}
          call with me today.
        </p>
      ),
    },
    {
      key: '2',
      header: (
        <p className='small-title'>Can I make this work on a busy schedule?</p>
      ),
      text: (
        <p className='collapse-text'>
          Absolutely. Waiting for the perfect time could be an ever lasting
          wait. My coaching is especially targeted to busy professionals and
          entrepreneurs like you, so rest assured you'll be in good hands. When
          building your program, I will consider your schedule and preferences.
        </p>
      ),
    },
    {
      key: '3',
      header: (
        <p className='small-title'>What if I travel regularly for work?</p>
      ),
      text: (
        <p className='collapse-text'>
          If you travel regularly, I'll add options in your plan when you don't
          have access to equipment. Elastic bands and body weight exercises are
          great alternatives.
        </p>
      ),
    },
    {
      key: '4',
      header: (
        <p className='small-title'>How long is the transformation program?</p>
      ),
      text: (
        <p className='collapse-text'>
          Since my program is 100% customized to your individual situation, in
          our consultation, I will be able to provide a more accurate timing
          based on where you are currently, and the results you are looking for.
          Usually, the programs can vary anywhere from 10 to 32 weeks.
        </p>
      ),
    },
    {
      key: '5',
      header: <p className='small-title'>Who is the coaching program for?</p>,
      text: (
        <p className='collapse-text'>
          That's an excellent question. This program is tailored for those who
          are serious about making a complete transformation and excel not only
          in their fitness but also in other aspects of life.
          <br />
          <br />
          If any of the following statements resonate with you, then you're in
          the right place:
          <br />
          <br />
          "I'm feeling exhausted, stressed, and lacking energy, and I want to
          regain my confidence."
          <br />
          <br />
          "I've veered off track with my health and fitness habits due to
          putting other things first, but I am ready to get the right guidance
          and accountability to make a transformation."
          <br />
          <br />
          "I constantly doubt whether my current approach is effective, and I
          crave a proven program tailored to my needs."
          <br />
          <br />
          "I would love to lose weight and build muscle over the next three
          months and I am committed to put in the work and effort in a program
          that works."
          <br />
          <br />
          "I need a sophisticated and data-driven approach to coaching, not an
          outdated technology lacking method."
          <br />
          <br />
          If any of these statements reflect your current situation, rest
          assured that my coaching can provide the support and guidance you
          need.
        </p>
      ),
    },
    {
      key: '6',
      header: (
        <p className='small-title'>
          What level of accountability do you provide?
        </p>
      ),
      text: (
        <p className='collapse-text'>
          I take great pride in the level of support I provide to my clients.
          <br />
          <br />
          When you join me, we will start with a thorough onboarding meeting.
          During this meeting, we will discuss the program in detail and set
          clear expectations.
          <br />
          <br />
          Once the program begins, you will have access to myself when you need
          it. I believe in maintaining a personal connection, so you can expect
          to hear from me often.
          <br />
          <br />
          This ongoing support ensures that your questions and concerns are
          addressed, and you have the guidance you need at every step.
          <br />
          <br />
          Additionally, we will have a weekly check-in on your first workout of
          the week. It serves as an important accountability checkpoint,
          allowing us to review your progress, celebrate milestones, and make
          any necessary adjustments to your program.
          <br />
          <br />
          With this comprehensive support system in place, you can rest assured
          that you will receive a high level of accountability and guidance
          throughout your training. Your success is my priority, and I am
          dedicated to being there for you every step of the way.
        </p>
      ),
    },
  ],
};
