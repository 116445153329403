import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Button, Modal, Select, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { capitalize } from '../../helpers/utils';
import { generateMealPlan } from '../../helpers/utils';
import { MealPlanLoading } from './customMealPlanLoading';
import { setMealPlanAnswers } from '../../ducks/Global/actions';
import './index.css';

export const GenerateMealplanModal = ({
  macroResults,
  isAdvancedMode,
  isMobileView,
}) => {
  const { t } = useTranslation();
  const advancedModeMacros = useSelector(
    (state) => state.global.advancedModeMacros
  );

  const isGenerateMealPlanModalVisible = useSelector(
    (state) => state.global.isGenerateMealPlanModalVisible
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [percentageError, setPercentageError] = useState(false);
  const mealPlanAnswers = useSelector((state) => state.global.mealPlanAnswers);
  const auth = useSelector((state) => state.auth);
  const [isGeneratingMealPlan, setIsGeneratingMealPlan] = useState(false);
  const [selectedMacros, setSelectedMacros] = useState({});
  const prevGoalRef = useRef(null);
  const [macroClasses, setMacroClasses] = useState({
    calories: '',
    protein: '',
    carbs: '',
    fat: '',
  });

  const selectedGoal = useMemo(() => {
    return macroResults?.macrosForEachGoal
      ? Object.entries(macroResults.macrosForEachGoal).find(
          ([, goal]) => goal.isSelected
        )
      : null;
  }, [macroResults]);

  //changes the values for standard more when selecting the macro distribution
  useEffect(() => {
    if (!isAdvancedMode && selectedGoal) {
      const [key, goal] = selectedGoal;
      setSelectedMacros((prev) => {
        // Only update state if values have changed
        const isDifferent =
          prev.goal !== capitalize(key.split('-').join(' ')) ||
          prev.protein !== goal.protein ||
          prev.carbs !== goal.carbs ||
          prev.fat !== goal.fat ||
          prev.calories !== goal.calories;

        if (isDifferent) {
          return {
            goal: capitalize(key.split('-').join(' ')),
            protein: goal.protein,
            carbs: goal.carbs,
            fat: goal.fat,
            calories: goal.calories,
          };
        }
        return prev; // Avoid unnecessary state updates
      });
    }
  }, [selectedGoal, isAdvancedMode]);

  useEffect(() => {
    if (macroResults) {
      const selectedGoal = Object.values(macroResults.macrosForEachGoal).find(
        (goal) => goal.isSelected
      );

      if (selectedGoal) {
        const prevGoal = prevGoalRef.current || {}; // Get previous goal, default to an empty object
        const newClasses = {};

        if (selectedGoal.calories !== prevGoal.calories) {
          newClasses.calories = 'macro-value-change';
        }
        if (selectedGoal.protein !== prevGoal.protein) {
          newClasses.protein = 'macro-value-change';
        }
        if (selectedGoal.carbs !== prevGoal.carbs) {
          newClasses.carbs = 'macro-value-change';
        }
        if (selectedGoal.fat !== prevGoal.fat) {
          newClasses.fat = 'macro-value-change';
        }

        setMacroClasses(newClasses);

        // Update prevGoalRef to the current selectedGoal for the next render
        prevGoalRef.current = selectedGoal;

        // Reset animation classes after animation ends
        const timeout = setTimeout(() => {
          setMacroClasses({
            calories: '',
            protein: '',
            carbs: '',
            fat: '',
          });
        }, 500);

        return () => clearTimeout(timeout);
      }
    }
  }, [macroResults]);

  const macroDistributionTypes = [
    {
      value: 'balanced',
      label: t('tabs.balanced.label'),
      explanation: t('tabs.balanced.explanation'),
    },
    {
      value: 'low-carb',
      label: t('tabs.low-carb.label'),
      explanation: t('tabs.low-carb.explanation'),
    },
    {
      value: 'high-carb',
      label: t('tabs.high-carb.label'),
      explanation: t('tabs.high-carb.explanation'),
    },
    {
      value: 'high-protein',
      label: t('tabs.high-protein.label'),
      explanation: t('tabs.high-protein.explanation'),
    },
    {
      value: 'ketogenic',
      label: t('tabs.ketogenic.label'),
      explanation: t('tabs.ketogenic.explanation'),
    },
    {
      value: 'custom',
      label: t('tabs.custom.label'),
      explanation: t('tabs.custom.explanation'),
    },
  ];

  const mealsPerDay = [
    {
      key: 3,
      value: 3,
    },
    {
      key: 4,
      value: 4,
    },
    {
      key: 5,
      value: 5,
    },
    {
      key: 6,
      value: 6,
    },
  ];

  const dietType = [
    {
      key: 1,
      value: 'Standard',
    },
    {
      key: 2,
      value: 'Vegetarian',
    },
  ];

  const largestMeal = [
    {
      key: 1,
      value: 'Distribute equally',
    },
    {
      key: 2,
      value: 'Breakfast',
    },
    {
      key: 3,
      value: 'Lunch',
    },
    {
      key: 4,
      value: 'Dinner',
    },
  ];

  const mealPlanQuestions = [
    {
      label: 'Preferred meals per day (Including snacks)',
      question: 'numberOfMeals',
      options: mealsPerDay,
    },
    {
      label: 'Diet type',
      question: 'dietType',
      options: dietType,
    },
    {
      label: 'Largest meal of the day',
      question: 'largestMeal',
      options: largestMeal,
    },
  ];

  const renderCustomMacrosInput = () => {
    if (mealPlanAnswers.isCustomMacros) {
      return (
        <div className='mb-20'>
          <div className='custom-macros-input-container'>
            {Object.entries(mealPlanAnswers.customMacros).map((macro) => (
              <Input
                onChange={(e) =>
                  setCustomMacros(macro[0], Number(e.target.value))
                }
                value={mealPlanAnswers.customMacros[macro[0]]}
                type='number'
                addonAfter={`${capitalize(macro[0])} %`}
                maxLength={2}
              />
            ))}
          </div>
          {percentageError ? (
            <p
              className='mb-20'
              style={{ textAlign: 'center', color: 'red', fontSize: '14px' }}
            >
              Macro percentages must equal 100%
            </p>
          ) : null}
        </div>
      );
    }
  };

  const setCustomMacros = (macroType, percentage) => {
    const updatedMacros = {
      ...mealPlanAnswers.customMacros,
      [macroType]: percentage,
    };

    // Calculate the remaining macro percentage
    const totalPercentage =
      updatedMacros.protein + updatedMacros.carbs + updatedMacros.fat;

    setPercentageError(totalPercentage !== 100);

    dispatch(
      setMealPlanAnswers({
        ...mealPlanAnswers,
        customMacros: updatedMacros,
      })
    );

    const updatedMacrosForEachGoal = Object.fromEntries(
      Object.entries(macroResults.macrosForEachGoal).map(([key, goal]) => [
        key,
        {
          ...goal,
          ...calculateMacrosWithCustomValues(goal.calories, updatedMacros),
        },
      ])
    );

    dispatch({
      type: 'SET_MACRO_CALCULATION_RESULTS',
      payload: {
        ...macroResults,
        macrosForEachGoal: updatedMacrosForEachGoal,
      },
    });
  };

  const calculateMacrosWithCustomValues = (calories, customMacros) => {
    return {
      protein: Math.round(((customMacros.protein / 100) * calories) / 4),
      carbs: Math.round(((customMacros.carbs / 100) * calories) / 4),
      fat: Math.round(((customMacros.fat / 100) * calories) / 9),
    };
  };

  const setMacroPreference = (value) => {
    if (!macroResults) return;

    const calculateMacros = (
      calories,
      proteinPercent,
      carbPercent,
      fatPercent
    ) => ({
      protein: Math.round((proteinPercent * calories) / 4),
      carbs: Math.round((carbPercent * calories) / 4),
      fat: Math.round((fatPercent * calories) / 9),
    });

    // Update macros for each goal based on the selected preference
    const updatedMacrosForEachGoal = Object.fromEntries(
      Object.entries(macroResults.macrosForEachGoal).map(([key, goal]) => {
        const calories = goal.calories;
        let updatedGoal;

        switch (value) {
          case 'balanced':
            updatedGoal = calculateMacros(calories, 0.35, 0.35, 0.3);
            break;
          case 'low-carb':
            updatedGoal = calculateMacros(calories, 0.4, 0.2, 0.4);
            break;
          case 'high-carb':
            updatedGoal = calculateMacros(calories, 0.3, 0.5, 0.2);
            break;
          case 'high-protein':
            updatedGoal = calculateMacros(calories, 0.4, 0.3, 0.3);
            break;
          case 'ketogenic':
            updatedGoal = calculateMacros(calories, 0.4, 0.1, 0.5);
            break;
          case 'custom':
            updatedGoal = calculateMacros(
              calories,
              mealPlanAnswers.customMacros.protein / 100,
              mealPlanAnswers.customMacros.carbs / 100,
              mealPlanAnswers.customMacros.fat / 100
            );
            break;
          default:
            updatedGoal = calculateMacros(calories, 0.35, 0.35, 0.3);
            break;
        }

        return [
          key,
          {
            ...goal,
            ...updatedGoal,
          },
        ];
      })
    );

    dispatch({
      type: 'SET_MACRO_CALCULATION_RESULTS',
      payload: {
        ...macroResults,
        macrosForEachGoal: updatedMacrosForEachGoal,
      },
    });

    dispatch(
      setMealPlanAnswers({
        ...mealPlanAnswers,
        macroPreference: value,
        isCustomMacros: value === 'custom',
      })
    );
  };

  const handleGenerateMealPlan = () => {
    const scrollableElement = document.documentElement || document.body;

    scrollableElement.scrollTo({
      top: 0,
    });

    if (!auth.isAuthenticated) {
      setIsGeneratingMealPlan(true);
    }

    const timeoutDuration = auth.isAuthenticated ? 0 : 6000;

    dispatch({ type: 'HANDLE_GENERATE_MEALPLAN_MODAL', payload: false });

    //check why selected macros is not present when in advanced mode
    setTimeout(() => {
      let mealPlan = generateMealPlan(
        isAdvancedMode ? advancedModeMacros : selectedMacros,
        mealPlanAnswers.dietType,
        mealPlanAnswers.numberOfMeals,
        mealPlanAnswers.largestMeal
      );

      navigate('/tools/macronutrient-calculator/results/custom-meal-plan', {
        state: {
          mealPlan,
          dietType: mealPlanAnswers.dietType,
          isAdvancedMode,
        },
      });

      if (!auth.isAuthenticated) {
        setIsGeneratingMealPlan(false);
        dispatch({ type: 'OPEN_AUTH_MODAL', payload: true });
      }
    }, timeoutDuration);
  };

  const renderSelectedGoalAndMacros = () => {
    const dataSource = isAdvancedMode
      ? advancedModeMacros
      : macroResults?.macrosForEachGoal &&
        Object.entries(macroResults.macrosForEachGoal).find(
          ([, goal]) => goal.isSelected
        )?.[1]; // Get the selected goal's data

    if (!dataSource) {
      return null;
    }

    return (
      <div
        className='macro-card'
        style={{
          background: `linear-gradient(135deg, #00BFB3, #8A4FFF)`,
          padding: `${isMobileView ? '18px 10px' : '18px'}`,
          borderRadius: '10px',
        }}
      >
        <div className='flex space-between'>
          <p className='eyebrow-text white mb-10'>
            {!isAdvancedMode
              ? capitalize(
                  Object.keys(macroResults.macrosForEachGoal)
                    .find(
                      (key) =>
                        macroResults.macrosForEachGoal[key] === dataSource
                    )
                    ?.split('-')
                    .join(' ')
                )
              : 'Selected Macros'}
          </p>
        </div>
        <div className='flex space-between'>
          <div className='grid justify-center'>
            <p className={`macro-number bold ${macroClasses.calories}`}>
              {dataSource.calories}
            </p>
            <p className='subtitle'>Calories</p>
          </div>
          <div className='grid justify-center'>
            <p className={`macro-number bold ${macroClasses.protein}`}>
              {dataSource.protein}g
            </p>
            <p className='subtitle'>Protein</p>
          </div>
          <div className='grid justify-center'>
            <p className={`macro-number bold ${macroClasses.carbs}`}>
              {dataSource.carbs}g
            </p>
            <p className='subtitle'>Carbs</p>
          </div>
          <div className='grid justify-center'>
            <p className={`macro-number bold ${macroClasses.fat}`}>
              {dataSource.fat}g
            </p>
            <p className='subtitle'>Fat</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {isGeneratingMealPlan ? (
        <MealPlanLoading onComplete={() => setIsGeneratingMealPlan(false)} />
      ) : (
        <Modal
          maskStyle={{
            backgroundColor: 'black',
            opacity: '0.8',
          }}
          className='generate-mealplan-modal dark'
          open={isGenerateMealPlanModalVisible}
          footer={null}
          closable={true}
          onCancel={() =>
            dispatch({ type: 'HANDLE_GENERATE_MEALPLAN_MODAL', payload: false })
          }
          centered={true}
        >
          <div className='mb-20 mt-10'>{renderSelectedGoalAndMacros()}</div>
          {!isAdvancedMode && (
            <>
              <div>
                <p className='label'>Macronutrient Preference</p>
                <Select
                  className='mb-20'
                  options={macroDistributionTypes}
                  defaultValue='balanced'
                  style={{
                    width: '100%',
                    border: '1px solid transparent',
                  }}
                  onChange={(value) => setMacroPreference(value)}
                  value={mealPlanAnswers?.macroPreference || null}
                />
                <p className='subtitle mb-20' style={{ color: 'white' }}>
                  {macroDistributionTypes.find(
                    (type) => type.value === mealPlanAnswers?.macroPreference
                  )?.explanation || 'No explanation available'}
                </p>
              </div>
              {renderCustomMacrosInput()}
            </>
          )}
          {mealPlanQuestions.map((question) => (
            <div>
              <p className='label'>{question.label}</p>
              <Select
                className='mb-20'
                options={question.options}
                value={mealPlanAnswers[question.question]}
                style={{
                  width: '100%',
                  border: '1px solid transparent',
                }}
                onChange={(value) =>
                  dispatch(
                    setMealPlanAnswers({
                      ...mealPlanAnswers,
                      [question.question]: value,
                    })
                  )
                }
              />
            </div>
          ))}
          <Button
            type='primary'
            size='large'
            className='landing-button generate-meal-plan full-width-button'
            onClick={() => handleGenerateMealPlan()}
            disabled={percentageError}
          >
            Generate Your Meal Plan
          </Button>
        </Modal>
      )}
    </div>
  );
};
