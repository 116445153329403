import React, { useEffect, useRef, useState } from 'react';
import Peer from 'simple-peer';
import { getSocket } from '../../services/socket';
import './index.css';

const WatchStream = () => {
  const socket = useRef(null);
  const peersRef = useRef([]);
  const [stream, setStream] = useState(null);
  const [loading, setLoading] = useState(true);
  const videoRef = useRef();
  const retryIntervalRef = useRef(null);
  const retryTimeoutRef = useRef(null);

  const initializeConnection = () => {
    console.log('📡 Initializing connection to stream');
    setLoading(true);

    // Clear any existing connections
    peersRef.current.forEach(({ peer }) => {
      if (peer && typeof peer.destroy === 'function') {
        peer.destroy();
      }
    });
    peersRef.current = [];

    // Tell server we're ready to receive the stream
    if (socket.current) {
      socket.current.emit('viewer-ready');
    }

    // Set a timeout to change loading state if no stream is received
    clearTimeout(retryTimeoutRef.current);
    retryTimeoutRef.current = setTimeout(() => {
      if (!stream) {
        setLoading(false);
        // Start auto-retry if no stream is found
        startAutoRetry();
      }
    }, 5000); // Wait 5 seconds before showing "No stream available"
  };

  const startAutoRetry = () => {
    // Clear any existing retry interval
    clearInterval(retryIntervalRef.current);

    // Set up a new retry interval (every 10 seconds)
    retryIntervalRef.current = setInterval(() => {
      console.log('🔄 Auto-retrying connection to stream');
      if (!stream) {
        initializeConnection();
      } else {
        // If we have a stream, stop retrying
        clearInterval(retryIntervalRef.current);
      }
    }, 10000); // Try every 10 seconds
  };

  const stopAutoRetry = () => {
    clearInterval(retryIntervalRef.current);
    clearTimeout(retryTimeoutRef.current);
  };

  useEffect(() => {
    socket.current = getSocket();

    console.log('📡 WatchStream component mounted');

    socket.current.emit('join-room', 'live-room');

    // Initial connection attempt
    initializeConnection();

    // Check stream status
    socket.current.on('stream-status', (status) => {
      console.log(
        `📡 Stream status update: ${status.active ? 'active' : 'inactive'}`
      );
      if (!status.active) {
        // No active stream
        setLoading(false);
        startAutoRetry();
      }
    });

    socket.current.on('signal', ({ signal, from }) => {
      console.log(`📡 Received WebRTC signal from admin: ${from}`);

      let existingPeerItem = peersRef.current.find((p) => p.id === from);

      if (existingPeerItem) {
        console.log('📡 Signaling existing peer');
        existingPeerItem.peer.signal(signal);
      } else {
        console.log('📡 Creating new peer from signal');
        const peer = addPeer(signal, from);
        peersRef.current.push({ id: from, peer });
      }
    });

    // Listen for stream-ended event from socket
    socket.current.on('stream-ended', () => {
      console.log('🛑 Received stream-ended event from server');
      handleStreamEnded();
    });

    // Listen for admin disconnected event
    socket.current.on('admin-disconnected', () => {
      console.log('🛑 Admin disconnected');
      handleStreamEnded();
    });

    return () => {
      console.log('📡 Cleaning up WatchStream listeners');
      if (socket.current) {
        socket.current.off('signal');
        socket.current.off('stream-ended');
        socket.current.off('stream-status');
        socket.current.off('admin-disconnected');
      }
      peersRef.current.forEach(({ peer }) => {
        if (peer && typeof peer.destroy === 'function') {
          peer.destroy();
        }
      });
      peersRef.current = [];
      stopAutoRetry();
    };
  }, []);

  // Stop auto-retry when we get a stream
  useEffect(() => {
    if (stream) {
      stopAutoRetry();
    }
  }, [stream]);

  function handleStreamEnded() {
    console.log('🛑 Stream has ended, updating UI');

    // Clear the video srcObject
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }

    // Update state to show "No stream available"
    setStream(null);
    setLoading(false);

    // Start auto-retry when stream ends
    startAutoRetry();
  }

  function addPeer(incomingSignal, callerId) {
    console.log(`📡 Adding peer for caller: ${callerId}`);

    const peer = new Peer({
      initiator: false,
      trickle: false,
    });

    peer.on('signal', (signal) => {
      console.log(`📡 Sending answer back to: ${callerId}`);
      socket.current.emit('signal', {
        target: callerId,
        signal,
        from: socket.current.id,
      });
    });

    peer.on('stream', (incomingStream) => {
      console.log('✅ Viewer received stream:', incomingStream);
      console.log('🎥 Stream tracks:', incomingStream.getTracks());

      setStream(incomingStream);
      setLoading(false);

      if (videoRef.current) {
        videoRef.current.srcObject = incomingStream;
        videoRef.current.play().catch((error) => {
          console.warn('Autoplay failed:', error);
        });
      }

      // Set up track ended listeners
      incomingStream.getTracks().forEach((track) => {
        track.onended = () => {
          console.log('🛑 Stream track ended:', track.kind);
          handleStreamEnded();
        };
      });
    });

    peer.on('close', () => {
      console.log('🛑 Peer connection closed');
      handleStreamEnded();
    });

    peer.on('error', (err) => {
      console.error('🛑 Peer connection error:', err);
      handleStreamEnded();
    });

    peer.signal(incomingSignal);
    return peer;
  }

  // Try to play the video when the stream changes
  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;

      // Try to autoplay when the stream is set
      videoRef.current.muted = true; // Mute initially to help with autoplay policies
      const playPromise = videoRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log('🎥 Autoplay successful');
          })
          .catch((error) => {
            console.warn('Autoplay was prevented:', error);
          });
      }

      // Add ended event listener to video element
      videoRef.current.onended = () => {
        console.log('🛑 Video ended event triggered');
        handleStreamEnded();
      };
    }
  }, [stream]); // ✅ Attach stream when it's set

  const handleManualRetry = () => {
    // Stop the auto-retry and initiate a manual connection
    stopAutoRetry();
    initializeConnection();
  };

  return (
    <div className='live-stream-container'>
      {/* Video component - only show when stream exists */}
      {stream && (
        <>
          <div className='column-left'>
            <video
              ref={videoRef}
              autoPlay
              playsInline
              muted // Initially muted to help with autoplay
              controls
              style={{
                width: '100%',
                height: 'calc(100vh - 96px)',
              }}
            />
          </div>
          <div className='column-right'>
            <div className='small-title'>Stream Title</div>
          </div>
        </>
      )}

      {/* Loading state */}
      {loading && (
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 96px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f0f0f0',
            flexDirection: 'column',
          }}
        >
          <p>Connecting to stream...</p>
        </div>
      )}

      {/* No stream available state */}
      {!stream && !loading && (
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 96px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f0f0f0',
            flexDirection: 'column',
          }}
        >
          <div>
            <p style={{ textAlign: 'center', fontSize: '18px' }}>
              No stream available right now
            </p>
            <p style={{ textAlign: 'center', fontSize: '14px' }}>
              Automatically checking for stream...
            </p>
            <button
              onClick={handleManualRetry}
              style={{
                marginTop: '20px',
                padding: '8px 16px',
                backgroundColor: '#4a90e2',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Check Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WatchStream;
