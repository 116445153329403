import React from 'react';
import { useDispatch } from 'react-redux';

export const TopBanner = (text, style, action) => {
  const dispatch = useDispatch();
  return (
    <div
      className='flex align-center justify-center text-center'
      style={{
        backgroundColor: 'black',
        padding: '8px',
      }}
    >
      <p
        className='banner-text'
        onClick={() =>
          dispatch({ type: 'HANDLE_SPECIAL_OFFER_MODAL', payload: true })
        }
      >
        🎉 Join Now! New Client March Special Inside!
      </p>
    </div>
  );
};
