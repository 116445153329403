import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AlvaroFitnessBlack from '../../assets/images/af-logo-black.png';
import { connect } from 'react-redux';
import { logout } from '../../ducks/Auth/actions';
import { handleDrawerMenu } from '../../ducks/Global/actions';
import { GiHamburgerMenu } from 'react-icons/gi';
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Space } from 'antd';
import { setAuthToken } from '../../helpers/utils';
import './index.css';

const NavBar = ({
  auth,
  logout,
  isMobileView,
  isDrawerOpen,
  sectionsRefs,
  handleScroll,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isRecommendationPage, setIsRecommendationPage] = useState(false);

  useEffect(() => {
    const checkRecommendationPage = () => {
      const isRecommendation = location.pathname === '/transformation-program';

      setIsRecommendationPage(isRecommendation);
    };
    checkRecommendationPage();
  }, [location]);

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('token');
    setAuthToken(null);
    navigate('/');
  };

  const menu = (
    <Menu>
      <div
        className='subtitle'
        style={{ padding: '8px 16px', cursor: 'default' }}
      >
        {auth.isAuthenticated && auth?.user?.email && auth.user.email}
      </div>
      <Menu.Divider />
      <Menu.Item
        className='text'
        key='1'
        onClick={() => navigate('/live-stream')}
      >
        Live Stream
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item className='text' key='2' onClick={() => handleLogout()}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const renderNavLinks = () => {
    let navLinks = [
      { key: 1, name: 'Menu', path: '/meals/menu' },
      { key: 2, name: 'Orders', path: '/meals/orders', private: true },
      {
        key: 3,
        name: 'Orders Detail',
        path: '/meals/orders-detail',
        private: true,
      },
      {
        key: 4,
        name: 'Manage Meals',
        path: '/meals/manage-meals',
        private: true,
      },
    ];

    let nav = [
      {
        key: 1,
        name: 'Training',
        path: '/',
      },
      {
        key: 2,
        name: 'DIY Plan',
        path: '/transformation-program',
      },
      {
        key: 3,
        name: 'About',
        path: '/about',
      },
      {
        key: 4,
        name: 'Meal Plan Generator',
        path: '/tools/macronutrient-calculator',
      },
      {
        key: 5,
        name: 'Contact',
        path: '#contact',
      },
    ];

    const links =
      auth.token && location.pathname.includes('meals') ? navLinks : nav;

    return links.map((link) =>
      link.name === 'Contact' ? (
        <div
          key={link.key}
          className='nav-link'
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          onClick={(e) => {
            e.preventDefault();
            navigate('/', { state: { scrollTo: '#home-section-8' } });
          }}
        >
          <p>{link.name}</p>
        </div>
      ) : (
        <NavLink
          key={link.key}
          to={link.path}
          end={link.path === '/'}
          className={({ isActive }) =>
            `nav-link ${isActive ? 'active-tab' : ''}`
          }
        >
          <p>{link.name}</p>
        </NavLink>
      )
    );
  };

  return (
    <div
      className='nav-bar'
      style={{
        backgroundColor: isRecommendationPage ? 'white' : 'white',
      }}
    >
      <div className='nav-bar-content'>
        <img
          className='logo'
          src={isRecommendationPage ? AlvaroFitnessBlack : AlvaroFitnessBlack}
          alt='logo'
          onClick={() => navigate('/')}
        />
        {!isMobileView && (
          <>
            <div className='nav-links-container'>{renderNavLinks()}</div>
            <div>
              {auth.isAuthenticated ? (
                <Dropdown overlay={menu} trigger={['click']}>
                  <Space>
                    <span
                      className='text'
                      style={{ color: '#2726f1', cursor: 'pointer' }}
                    >
                      Welcome, {auth?.user?.name}
                      <DownOutlined style={{ marginLeft: '5px' }} />
                    </span>
                  </Space>
                </Dropdown>
              ) : (
                <div
                  className='tab-btn'
                  onClick={() => {
                    dispatch({ type: 'SET_IS_LOGIN', payload: true });
                    navigate('/login');
                  }}
                >
                  Login
                </div>
              )}
            </div>
          </>
        )}

        {isMobileView && (
          <GiHamburgerMenu
            style={{ fontSize: '32px', cursor: 'pointer' }}
            onClick={() => dispatch(handleDrawerMenu(!isDrawerOpen))}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
