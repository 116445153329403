export const questionOptions = (t, heightOptions) => ({
  gender: {
    name: t('questions.gender.name'),
    options: [
      { name: t('questions.gender.options.male'), value: 'male' },
      { name: t('questions.gender.options.female'), value: 'female' },
    ],
    placeholder: 'Gender...',
  },
  age: {
    name: t('questions.age.name'),
    label: t('questions.age.label'),
    placeholder: 'Age...',
  },
  weight: {
    name: t('questions.weight.name'),
    label: t('questions.weight.label'),
    placeholder: 'Weight...',
  },
  height: {
    name: t('questions.height.name'),
    options: heightOptions,
    placeholder: 'Height...',
  },
  activityFactor: {
    name: t('questions.activityFactor.name'),
    options: [
      { name: t('questions.activityFactor.options.1.2'), value: 1.2 },
      { name: t('questions.activityFactor.options.1.375'), value: 1.375 },
      { name: t('questions.activityFactor.options.1.55'), value: 1.55 },
      { name: t('questions.activityFactor.options.1.725'), value: 1.725 },
      { name: t('questions.activityFactor.options.1.9'), value: 1.9 },
    ],
    placeholder: 'Activity Factor...',
  },
  bmrFormula: {
    name: t('questions.bmrFormula.name'),
    options: [
      {
        name: t('questions.bmrFormula.options.mifflin-st-jeor.name'),
        value: 'mifflin-st-jeor',
        explanation: t(
          'questions.bmrFormula.options.mifflin-st-jeor.explanation'
        ),
      },
      {
        name: t('questions.bmrFormula.options.katch-mcardle.name'),
        value: 'katch-mcardle',
        explanation: t(
          'questions.bmrFormula.options.katch-mcardle.explanation'
        ),
      },
      {
        name: t('questions.bmrFormula.options.harris-benedict.name'),
        value: 'harris-benedict',
        explanation: t(
          'questions.bmrFormula.options.harris-benedict.explanation'
        ),
      },
    ],
    placeholder: null,
  },
  goal: {
    name: t('questions.goal.name'),
    options: [
      {
        name: t('questions.goal.options.rapid-weight-loss.name'),
        value: 'rapid-weight-loss',
        explanation: t('questions.goal.options.rapid-weight-loss.explanation'),
      },
      {
        name: t('questions.goal.options.moderate-weight-loss.name'),
        value: 'moderate-weight-loss',
        explanation: t(
          'questions.goal.options.moderate-weight-loss.explanation'
        ),
      },
      {
        name: t('questions.goal.options.slow-weight-loss.name'),
        value: 'slow-weight-loss',
        explanation: t('questions.goal.options.slow-weight-loss.explanation'),
      },
      {
        name: t('questions.goal.options.maintain-weight.name'),
        value: 'maintain-weight',
        explanation: t('questions.goal.options.maintain-weight.explanation'),
      },
      {
        name: t('questions.goal.options.slow-weight-gain.name'),
        value: 'slow-weight-gain',
        explanation: t('questions.goal.options.slow-weight-gain.explanation'),
      },
      {
        name: t('questions.goal.options.moderate-weight-gain.name'),
        value: 'moderate-weight-gain',
        explanation: t(
          'questions.goal.options.moderate-weight-gain.explanation'
        ),
      },
      {
        name: t('questions.goal.options.rapid-weight-gain.name'),
        value: 'rapid-weight-gain',
        explanation: t('questions.goal.options.rapid-weight-gain.explanation'),
      },
    ],
    placeholder: 'Goal...',
  },
});
