import React, { useEffect, useState } from 'react';
import NutritionPlan from '../../assets/images/NutritionPlan.png';
import WorkoutPlan from '../../assets/images/WorkoutPlan.png';
import SmartShoppingList from '../../assets/images/SmartShoppingList.png';
import AlternativeFoods from '../../assets/images/AlternativeFoods.png';
import ExerciseHistory from '../../assets/images/ExerciseHistory.png';
import Recipes from '../../assets/images/Recipes.png';
import Mockup from '../../assets/images/Mockup-min.png';
import Pritesh from '../../assets/images/PriteshFront.png';
import Corey from '../../assets/images/CoreyBack.png';
import Fide from '../../assets/images/FideFront.png';
import TelemundoLogo from '../../assets/images/TelemundoLogo.png';
import { faqData } from '../../data/faq';
import { RenderFaq } from '../../components/Faq';
import { GoogleReviews } from '../../components/GoogleReviews/index';
import { useSelector } from 'react-redux';
import { openReviewModal } from '../../ducks/Global/actions';
import { Button } from 'antd';
import { AiOutlineCheck } from 'react-icons/ai';
import { CountdownTimer } from '../../components/CountDownTimer';
import ReactGA from 'react-ga4';
import { ReviewModal } from '../../components/GoogleReviews/reviewModal';
import { LandingHeader } from '../../components/LandingPage/LandingHeader';
import MoneyBack from '../../assets/images/MoneyBack.png';
import axios from 'axios';
import Step1 from '../../assets/images/step-1.gif';
import Step2 from '../../assets/images/step-2.gif';
import Step3 from '../../assets/images/step-3.gif';
import Step4 from '../../assets/images/step-4.gif';
// import WorkoutPlan from '../../assets/images/ExerciseImg.png';
import './index.css';

const isProduction = process.env.NODE_ENV === 'production';

function ProgramResultsPage({ sectionsRefs, handleScroll }) {
  const [numberOfWorkouts, setNumberOfWorkouts] = useState(0);
  const [isTimerExpired, setTimerExpired] = useState(false);
  const isOpen = useSelector((state) => state.global.isOpen);
  const review = useSelector((state) => state.global.review);
  const [activeStep, setStep] = useState({
    key: 1,
    icon: Step1,
    title: 'Make the Most Important Decision',
    subtitle:
      'Purchase your program and kick-start the plan that will set you up for success.',
  });

  let expiryTimestamp = localStorage.getItem('expiryTimestamp');

  if (!expiryTimestamp) {
    const currentTime = Date.now();
    expiryTimestamp = currentTime + 24 * 60 * 60 * 1000;
    localStorage.setItem('expiryTimestamp', expiryTimestamp.toString());
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isProduction) {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }
  }, []);

  useEffect(() => {
    const currentTime = Date.now();
    if (expiryTimestamp <= currentTime) {
      setTimerExpired(true);
    }
  }, []);

  useEffect(() => {
    getWorkoutsCompleted();
  }, []);

  const handleStripeCheckoutClick = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Stripe Checkout Button',
    });

    const productUrl = isTimerExpired
      ? 'https://store.alvarofitness.com/personalized-program-offerexpiredex123/'
      : 'https://store.alvarofitness.com/personalized-program-offeractive2024a1/';

    window.open(productUrl, '_blank');
  };

  const getWorkoutsCompleted = async () => {
    try {
      const response = await axios.get(
        'https://api.getnubod.com/api/workout/library/count'
      );
      const workoutCount = response.data.count;
      setNumberOfWorkouts(workoutCount);
    } catch (error) {
      console.error('Error fetching workout count:', error);
    }
  };

  const renderNumberOfWorkouts = () => {
    return (
      <div className='section' style={{ background: '#1e1e1e' }}>
        <div className='number-of-workouts-container'>
          <p className='total-workouts-icon'>💪</p>
          <div className='text-section'>
            <p className='small-title mb-10' style={{ color: 'white' }}>
              The community has logged a total of{' '}
              <span className='text-gradient'>
                {numberOfWorkouts || 983} workouts
              </span>{' '}
              and counting!
            </p>
            {/* <p className='paragraph subtitle mb-20'>
              Join the movement and start your transformation now.
            </p> */}
          </div>
        </div>
      </div>
    );
  };

  const whatYouGet = [
    {
      key: 1,
      img: WorkoutPlan,
      title: 'Science-Backed Workout Split',
      subtitle:
        'Your workouts are built based on your goals, available time, and level, all tracked seamlessly in the app to ensure optimal progress.',
    },
    {
      key: 2,
      img: NutritionPlan,
      title: 'Personalized Nutrition Plan',
      subtitle:
        'Your nutrition plan is completely personalized based on your specific macro and calorie needs, diet type, and more. No more guessing what and when to eat.',
    },
    {
      key: 3,
      img: ExerciseHistory,
      title: 'App Functionality & Tools',
      subtitle:
        'No more PDF workouts—my app tracks workouts, exercise history, offers food swaps, and features a macronutrient calculator and compliance tool for smarter, data-driven progress.',
    },
    {
      key: 4,
      img: SmartShoppingList,
      title: 'Holistic Approach',
      subtitle:
        'The program covers more than just workouts—get lifestyle guidance, including sleep, stress management, and recovery techniques for all-around transformation.',
    },
    {
      key: 5,
      img: AlternativeFoods,
      title: 'Ongoing Support & Accountability',
      subtitle:
        'Stay connected to a supportive community and have direct access to your coach for continued motivation and accountability throughout the program.',
    },
    {
      key: 7,
      img: Recipes,
      title: 'Lifetime Access',
      subtitle:
        'You’ll have lifetime access to your workout program, logged workouts, exercise history, and meal plan.',
    },
  ];

  const howItWorks = [
    {
      key: 1,
      icon: Step1,
      title: 'Make the Most Important Decision',
      subtitle:
        'Purchase your program and kick-start the plan that will set you up for success.',
    },
    {
      key: 2,
      icon: Step2,
      title: 'Complete The Assessment',
      subtitle:
        'Open your email, complete the assessment and access your dashboard.',
    },
    {
      key: 3,
      icon: Step3,
      title: 'Get Ready',
      subtitle:
        'While your coach builds your program, take some time to explore the Learn section in your dashboard and familiarize yourself with key information.',
    },
    {
      key: 4,
      icon: Step4,
      title: 'Start Your Program',
      subtitle:
        'You will be notified when your program is ready! Access your workout and nutrition plan, and get ready to start making gains in not time.',
    },
  ];

  const pricingCardList = [
    'Personalized Nutrition Plan',
    'Science Based Workout Plan',
    'Ab Workouts',
    'Recovery & Stretching Routine',
    'Workout Logging Functionality',
    'Exercise History Data Dashboard',
    'Exercise Videos (Coming soon)',
    'Smart Shopping List Generator',
    'Alternative Foods Functionality',
    'Macronutrient Calculator',
    'Carb Cycling Weekly Builder Tool',
    'Macro Compliance Tool',
    'Connect to Fitbit Fod Log',
    'Private Support & Accountability Group',
    '30-Day Money Back Guaranteed',
  ];

  const benefits = [
    {
      icon: '💪',
      title: 'Tailored Workouts and Nutrition',
      subtitle:
        "Upon sign up, I will tailor a personalized workout and nutrition plan based on your goals and preferences. It's not one-size-fits-all; it's customized for you.",
    },
    {
      icon: '📈',
      title: 'Exercise History Tracking',
      subtitle:
        'Beyond simply monitoring your progress, this tool empowers you to hold yourself accountable and push yourself for more.',
    },
    {
      icon: '🛒',
      title: 'Smart Shopping List',
      subtitle:
        'The smart shopping list generator calculates items and quantities in your plan, saving you time and effort. No more guesswork.',
    },
    {
      icon: '🍽️',
      title: 'Alternative Foods',
      subtitle:
        'Every food item in your plan includes alternative options, enabling you to seamlessly swap for your preferred choice.',
    },
    {
      icon: '🥘',
      title: 'Thousands of Recipes',
      subtitle:
        "Craving a recipe over your scheduled meal? Easily get recommended recipes matching your meal's nutritional profile with just a simple click.",
    },
    {
      icon: '📸',
      title: 'Progress Picture Comparison',
      subtitle:
        'Track and compare your physical transformation with the easy-to-use progress picture feature.',
    },
    {
      icon: '📚',
      title: 'Access To Guides',
      subtitle:
        'Continuously expand your knowledge and skills with my ever-growing collection of comprehensive workout, nutrition, and lifestyle guides.',
    },
    {
      icon: '👥',
      title: 'Private Support & Accountability Group',
      subtitle:
        'Connect with a dedicated community and your coach to keep you on track until the end of your program and beyond.',
    },
    {
      icon: '🔗',
      title: 'Integrations',
      subtitle:
        'Seamlessly sync with Fitbit food log to access the unique macro compliance tool.',
    },
    {
      icon: '🚀',
      title: 'New Feature Releases',
      subtitle:
        "I'm dedicated to innovation. Get ready for constant releases of new features and improvements, ensuring your fitness journey stays fresh and exciting.",
    },
  ];

  return (
    <div>
      <ReviewModal
        isOpen={isOpen}
        openReviewModal={openReviewModal}
        review={review}
      />
      <div className='landing-page'>
        <LandingHeader
          title={
            'Shed Fat. Build Muscle. Transform Your Body—Faster Than Ever.'
          }
          subtitle={
            'The program that helps you unlock your potential through a straightforward, no-nonsense approach. Stick to the plan, transform your body for life.'
          }
          video={
            'https://res.cloudinary.com/alvaro-fitness/video/upload/v1727305604/4367578-hd_1920_1080_30fps_wlcfvn.mp4'
          }
          btnAction={() => handleScroll(sectionsRefs['#home-section-1'])}
          btnText={'Learn More'}
          textOverTitle={''}
        />

        <div className='featured-banner'>
          <div className='featured-logo'>
            <p className='subtitle bold'>Featured on</p>
            <img
              src={TelemundoLogo}
              alt='Telemundo'
              style={{ height: '60px' }}
            />
          </div>
        </div>

        <div ref={sectionsRefs['#home-section-1']} className='section black'>
          <div className='content' style={{ paddingTop: '0px' }}>
            <div className='grid grid justify-center mb-50'>
              <p className='title mb-20' style={{ textAlign: 'center' }}>
                What Makes This Program{' '}
                <span className='text-gradient underlined-title'>
                  A No-Brainer?
                </span>
              </p>
              <p
                className='big-subtitle'
                style={{
                  width: '100%',
                  maxWidth: '600px',
                  textAlign: 'center',
                }}
              >
                Easy To Follow · Enjoyable · Transformative
              </p>
            </div>
            <div className='container'>
              <div className='benefits'>
                {benefits.map((benefit) => (
                  <div className='global-card dark flex flex-center-aligned'>
                    <div className='benefits-info'>
                      <p style={{ fontSize: '50px', marginRight: '20px' }}>
                        {benefit.icon}
                      </p>
                      <div>
                        <p className='small-title mb-10'>{benefit.title}</p>
                        <p className='subtitle'>{benefit.subtitle}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Button
                className='landing-button'
                onClick={() => handleStripeCheckoutClick()}
                type='primary'
                style={{ backgroundColor: 'blue' }}
              >
                Get Your Personalized Program
              </Button>
            </div>
          </div>
        </div>
        <div ref={sectionsRefs['#home-section-2']} className='section white'>
          <div className='content'>
            <p className='title mb-50' style={{ textAlign: 'center' }}>
              What Clients{' '}
              <span className='text-gradient underlined-title'>Are Saying</span>
            </p>
            <div className='container'>
              <GoogleReviews color={'blue'} />
            </div>
          </div>
        </div>
        {renderNumberOfWorkouts()}
        <div
          ref={sectionsRefs['#home-section-3']}
          id='home-section-2'
          className='section gray'
        >
          <div className='content' style={{ alignContent: 'center' }}>
            <div className='what-you-get-item'>
              <div>
                <p className='eyebrow-text mb-10'>Transformation Program</p>
                <p className='title mb-20'>
                  These Are The{' '}
                  <span className='text-gradient underlined-title'>
                    Next Steps
                  </span>
                </p>
                <p
                  className='big-subtitle mb-20'
                  style={{
                    width: '100%',
                    maxWidth: '550px',
                  }}
                >
                  You are four steps away from an epic transformation.
                </p>
                <div>
                  {howItWorks.map((step, i) => (
                    <div
                      className='tab-container-horizontal'
                      style={{ maxWidth: '450px' }}
                    >
                      <div>
                        <div
                          className='tab-btn dark flex align-center'
                          onClick={() => setStep(step)}
                          style={{
                            borderRadius:
                              step.key === activeStep.key &&
                              '10px 10px 0px 0px',
                          }}
                        >
                          <span className='subtitle text-gradient space-between mr-10'>
                            {i + 1}.
                          </span>
                          <p className='subtitle bold'>{step.title}</p>
                        </div>

                        {step.title === activeStep.title && (
                          <div className='how-it-works-card'>
                            {activeStep.subtitle}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <img
                alt='Step'
                src={activeStep.icon}
                style={{
                  height: '350px',
                  alignSelf: 'flex-end',
                  borderRadius: '20px',
                }}
              />

              <div className='mobile-button-wrapper'>
                <Button
                  className='landing-button'
                  style={{ width: '100%', backgroundColor: 'blue!important' }}
                  type='primary'
                  onClick={() => handleStripeCheckoutClick()}
                >
                  Get Your Personalized Program
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div ref={sectionsRefs['#home-section-3']} className='section black'>
          <div className='content'>
            <div className='grid grid justify-center mb-50'>
              <p className='title mb-20' style={{ textAlign: 'center' }}>
                Here's Exactly What You Get With{' '}
                <span className='text-gradient underlined-title'>
                  The Program
                </span>
              </p>
              <p
                className='big-subtitle'
                style={{
                  width: '100%',
                  maxWidth: '600px',
                  textAlign: 'center',
                }}
              >
                Upon signing up, I will review your assessment and begin
                crafting a personalized workout and nutrition plan tailored for
                you.
              </p>
            </div>
            <div className='container'>
              {whatYouGet.map((item) => (
                <div className='global-card dark what-you-get-item'>
                  <img src={item.img} alt='Program Feature' />
                  <div className='text mt-20' style={{ textAlign: 'center' }}>
                    <p className='small-title text-gradient mb-10'>
                      {item.title}
                    </p>
                    <p className='subtitle'>{item.subtitle}</p>
                  </div>
                </div>
              ))}
              <Button
                className='landing-button'
                onClick={() => handleStripeCheckoutClick()}
                type='primary'
                style={{ backgroundColor: 'blue' }}
              >
                Get Your Personalized Program
              </Button>
            </div>
          </div>
        </div>

        <div ref={sectionsRefs['#home-section-5']} className='section white'>
          <div className='content' style={{ textAlign: 'center' }}>
            <div className='grid grid justify-center mb-50'>
              <p className='title mb-20' style={{ textAlign: 'center' }}>
                Success{' '}
                <span className='text-gradient underlined-title'> Stories</span>
              </p>
              <p
                className='big-subtitle mb-50'
                style={{
                  width: '100%',
                  maxWidth: '600px',
                  textAlign: 'center',
                }}
              >
                You Are Next.
              </p>
            </div>
            <div className='grid'>
              <div className='transformation-container'>
                <img src={Fide} className='transformation-img' alt='Client 1' />
                <img
                  src={Corey}
                  className='transformation-img'
                  alt='Client 2'
                />
                <img
                  src={Pritesh}
                  className='transformation-img'
                  alt='Client 3'
                />
              </div>
            </div>
            <Button
              className='landing-button mt-50'
              onClick={() => handleStripeCheckoutClick()}
              type='primary'
              style={{ backgroundColor: 'blue' }}
            >
              Get Your Personalized Program
            </Button>
          </div>
        </div>

        <div className='section' style={{ padding: '0 30px 80px 30px' }}>
          <div className='guarantee-section blue'>
            <div className='text-content'>
              <p className='small-title mb-10' style={{ color: 'white' }}>
                100% Money-Back Guarantee
              </p>
              <p className='subtitle' style={{ color: 'white' }}>
                With my Money Back Guarantee, your only risk is not taking
                action.
              </p>
            </div>
            <div className='guarantee-card'>
              <p>🧬 100% Personalization</p>
              <p>📉 No BS, Just Amazing Results</p>
              <p>✅ Noticeable Body Improvement</p>
            </div>
            <img src={MoneyBack} alt='Money Back' />
          </div>
        </div>

        <div ref={sectionsRefs['#home-section-6']} className='section black'>
          <div className='content'>
            <div>
              <div style={{ textAlign: 'center' }}>
                {!isTimerExpired ? (
                  <div className='grid grid justify-center mb-50'>
                    <p className='title mb-20'>
                      One-Time Payment for{' '}
                      <span className='text-gradient underlined-title'>
                        Lifetime Access!
                      </span>
                    </p>
                    <p className='big-subtitle'>
                      The value you get at this special price is unmatched.
                      Don’t wait—when the timer runs out, the price goes up.
                    </p>
                  </div>
                ) : (
                  <div className='grid grid justify-center mb-50'>
                    <p className='title mb-20' style={{ textAlign: 'center' }}>
                      Start Your{' '}
                      <span className='text-gradient underlined-title'>
                        Transformation, Today.
                      </span>
                    </p>
                    <p className='big-subtitle'>
                      This is the last program and app you'll ever need,
                      guaranteed.
                    </p>
                  </div>
                )}
              </div>

              <div
                className='pricing-container mt-30'
                style={{ background: '#1e1e1e' }}
              >
                <img src={Mockup} alt='Mockup' />
                <div className='list-container mb-30'>
                  <div className='grid justify-center'>
                    <div className='pricing-items  mt-20 mb-10'>
                      <p
                        className='discounted-price '
                        style={{ color: '#ff00a2' }}
                      >
                        {isTimerExpired ? '$247.00' : '$97.00'}
                      </p>
                      {!isTimerExpired && (
                        <>
                          <div className='button'>Save $150</div>
                          <p className='actual-price'>$247.00</p>
                        </>
                      )}
                    </div>
                    <div className='mb-30' style={{ textAlign: 'center' }}>
                      <CountdownTimer
                        expiryTimestamp={parseInt(expiryTimestamp)}
                      />
                    </div>
                  </div>

                  <div className='mb-30'>
                    {pricingCardList.map((listItem) => (
                      <div
                        className='subtitle flex align-center mb-10'
                        style={{ fontWeight: '500' }}
                      >
                        <AiOutlineCheck
                          className='mr-10'
                          style={{
                            fontSize: '24px',
                            color: 'blue',
                            marginRight: '5px',
                          }}
                        />
                        <p>{listItem}</p>
                      </div>
                    ))}
                  </div>
                  <Button
                    onClick={() => handleStripeCheckoutClick()}
                    className='landing-button full-width-button'
                    type='primary'
                    style={{ backgroundColor: 'blue' }}
                  >
                    Get Your Personalized Program
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={sectionsRefs['#home-section-6']}
          className='centered-container'
        >
          <div className='max-width-container'>
            <RenderFaq faqData={faqData.programFaq} isProductLanding={true} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramResultsPage;
