import {
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR,
  AUTH_ERROR,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  REQUEST_RESET_EMAIL,
  REQUEST_RESET_EMAIL_SUCCESS,
  REQUEST_RESET_EMAIL_ERROR,
  CLEAR_AUTH_ERROR,
  OPEN_AUTH_MODAL,
  SET_IS_LOGIN,
} from './constants';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  registrationLoading: false,
  user: null,
  userLoading: false,
  userLoaded: false,
  errors: [],
  success: null,
  isAuthModalVisible: false,
  isLogin: false,
};

function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REGISTER:
    case LOGIN_USER:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        registrationLoading: true,
        user: null,
        errors: [],
      };
    case REGISTER_SUCCESS:
    case LOGIN_USER_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        registrationLoading: false,
        user: payload.user,
        errors: [],
        success: null,
      };
    case AUTH_ERROR:
    case REGISTER_ERROR:
    case LOGIN_USER_ERROR:
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        registrationLoading: false,
        user: null,
        userLoading: false,
        errors: action.errors,
      };
    case LOAD_USER:
      return {
        ...state,
        userLoading: true,
        userLoaded: false,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
        userLoading: false,
        userLoaded: true,
        errors: [],
      };
    case LOAD_USER_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        userLoading: false,
        userLoaded: false,
        errors: action.errors,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        success: payload,
      };
    case REQUEST_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        success: payload,
        errors: [],
      };
    case REQUEST_RESET_EMAIL_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case CLEAR_AUTH_ERROR:
      return {
        ...state,
        errors: [],
      };
    case OPEN_AUTH_MODAL:
      return {
        ...state,
        isAuthModalVisible: action.payload,
      };
    case SET_IS_LOGIN:
      return {
        ...state,
        isLogin: action.payload,
      };
    default:
      return state;
  }
}

export default auth;
