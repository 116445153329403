import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleGlobalModal } from '../../ducks/Global/actions';
import { disclaimer, privacyPolicy } from '../../data//legal';
import AlvaroFitnessGradient from '../../assets/images/af-logo-white.png';
import { EmailForm } from '../Form/emailForm';
import { FaInstagram, FaFacebook } from 'react-icons/fa';
import './index.css';

export const Footer = ({ style, handleScroll, sectionsRefs }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleNavigation = (path) => {
    scrollToTop();
    navigate(path);
  };

  const socialLinks = {
    instagram: 'https://www.instagram.com/alvatros/',
    facebook: 'https://www.facebook.com/alvarofitnessofficial',
  };

  const linkToSocial = (social) => {
    const url = socialLinks[social];
    if (url) {
      window.open(url, '_blank');
    } else {
      console.warn('Social link not found!');
    }
  };

  return (
    <div className='footer' style={style}>
      <div className='footer-content'>
        <div className='footer-section'>
          <img alt='logo' className='logo mb-20' src={AlvaroFitnessGradient} />
          <div>
            <div>
              <p className='footer-link mb-5'>4101 Bryan St #140</p>
              <p className='footer-link mb-5'>Dallas, TX 75204</p>
            </div>
            <a href='mailto:contact@alvarofitness.com'>
              contact@alvarofitness.com
            </a>
          </div>
        </div>
        <div className='footer-section navigation'>
          <p className='bold mb-20' style={{ color: 'white' }}>
            Links
          </p>
          <p
            className='footer-link mb-20'
            onClick={() => handleNavigation('/')}
          >
            Training
          </p>
          <p
            className='footer-link mb-20'
            onClick={() => handleNavigation('/transformation-program')}
          >
            DIY Plan
          </p>
          <p
            className='footer-link mb-20'
            onClick={() => handleNavigation('/tools/macronutrient-calculator')}
          >
            Meal Plan Generator
          </p>
          <p
            className='footer-link mb-20'
            onClick={() => handleNavigation('/the-app')}
          >
            The App
          </p>
          <p
            className='footer-link mb-20'
            onClick={() => handleScroll(sectionsRefs['#home-section-8'])}
          >
            Contact
          </p>
          <p
            className='footer-link mb-20'
            onClick={() => dispatch(handleGlobalModal(true, privacyPolicy))}
          >
            Privacy Policy
          </p>

          <p
            className='footer-link mb-20'
            onClick={() => dispatch(handleGlobalModal(true, disclaimer))}
          >
            Disclaimer (Results may vary)
          </p>
        </div>
        <div>
          <EmailForm />
          <div className='mt-20'>
            <p className='bold mb-10' style={{ color: 'white' }}>
              Stay connected
            </p>
            <div className='flex'>
              <FaInstagram
                className=' icon mr-20'
                onClick={() => linkToSocial('instagram')}
              />
              <FaFacebook
                className=' icon'
                onClick={() => linkToSocial('facebook')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
