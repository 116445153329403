import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const PrivateRoute = ({ children, requiredRole }) => {
  const { isAuthenticated, userLoading, user } = useSelector(
    (state) => state.auth
  );
  const userRole = user?.role;

  console.log(isAuthenticated, userLoading, userRole);
  // If user is not authenticated and not loading, redirect to login
  if (!isAuthenticated) {
    return <Navigate to='/login' replace />;
  }

  // If user is authenticated but role doesn't match, redirect to unauthorized page
  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to='/unauthorized' replace />;
  }

  return children;
};

export default PrivateRoute;
