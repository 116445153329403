import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Popover, Select } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { feetToCentimeters } from '../helpers/utils';
import { MacronutrientCalculatorComponent } from '../components/MacroNutrientCalculatorComponent';
import {
  getMacronutrientAnswers,
  saveMacronutrientAnswers,
  setMacronutrientAnswers,
} from '../ducks/Global/actions';
import { useTranslation } from 'react-i18next';
import {
  generateImperialHeightOptions,
  generateMetricHeightOptions,
  capitalize,
} from '../helpers/utils';
import MacroLoading from '../components/MacroNutrientCalculatorComponent/macroLoading';
import { questionOptions } from '../data/macroQuestions';
import { mealPlanFeatures } from '../data/features';
import '../index.css';

const { Option } = Select;

export const MacronutrientCalculator = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobileView = useSelector((state) => state.global.isMobileView);
  const auth = useSelector((state) => state.auth);
  const macronutrientAnswers = useSelector(
    (state) => state.global.macronutrientAnswers
  );
  const macronutrientAnswersLoaded = useSelector(
    (state) => state.global.macronutrientAnswersLoaded
  );
  const [isCalculating, setIsCalculating] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  const [errors, setErrors] = useState({});
  const customMacros = useSelector(
    (state) => state.global.mealPlanAnswers.customMacros
  );
  const { t } = useTranslation();

  //Passing isAdvanced mode through navigate from /custom-mealplan component in order to set up advanced view and modal
  useEffect(() => {
    if (location.state?.isAdvancedMode) {
      setIsAdvancedMode(location.state.isAdvancedMode);
    }
  }, [location.state, setIsAdvancedMode]);

  useEffect(() => {
    if (auth.isAuthenticated && !macronutrientAnswersLoaded) {
      dispatch(getMacronutrientAnswers(macronutrientAnswers));
    }
  }, [auth.isAuthenticated, macronutrientAnswersLoaded, dispatch]);

  useEffect(() => {
    if (shouldNavigate) {
      navigate('/tools/macronutrient-calculator/results', {
        state: {
          isMobileView,
          isAdvancedMode,
        },
      });
      setShouldNavigate(false); // Reset the flag
    }
  }, [shouldNavigate, navigate, isMobileView, isAdvancedMode]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const heightOptions =
    macronutrientAnswers.unit === 'imperial'
      ? generateImperialHeightOptions(t)
      : generateMetricHeightOptions(t);

  const handleDropdownValue = (field, value) => {
    if (field === 'unit') {
      dispatch(
        setMacronutrientAnswers({
          ...macronutrientAnswers,
          unit: value,
          height: null,
        })
      );
    } else if (field === 'bmrFormula') {
      // Handle BMR formula changes
      dispatch(
        setMacronutrientAnswers({
          ...macronutrientAnswers,
          bmrFormula: value,
          bodyFatPercentage:
            value === 'mifflin-st-jeor' || value === 'harris-benedict'
              ? 15
              : macronutrientAnswers.bodyFatPercentage,
        })
      );
    } else {
      dispatch(
        setMacronutrientAnswers({
          ...macronutrientAnswers,
          [field]: value,
        })
      );
    }
  };

  const renderDropdown = (options, label, name, placeholder) => {
    let selectedOption = options.filter(
      (option) => option.value === macronutrientAnswers[name]
    )[0];

    return (
      <div className='mb-20'>
        {label === 'BMR Formula' ? (
          <div className='flex align-center'>
            <p className='label white mr-5'>{label}</p>
            <Popover
              trigger='click'
              content={
                <p
                  style={{ fontSize: '16px', width: '100%', maxWidth: '100px' }}
                >
                  {selectedOption.explanation}
                </p>
              }
            >
              <QuestionCircleOutlined
                style={{ fontSize: '16px', margin: '0 0 5px 3px' }}
              />
            </Popover>
          </div>
        ) : (
          <p className='label white'>{label}</p>
        )}
        <Select
          className='questionnaire-select'
          onSelect={(value) => handleDropdownValue(name, value)}
          value={macronutrientAnswers[name] || null}
          style={{ width: '100%' }}
          dropdownStyle={{
            borderRadius: '10px',
          }}
          placeholder={placeholder}
          virtual={label === 'Height' ? true : false}
        >
          {options.map((option, index) => (
            <Option key={index} value={option.value}>
              {option.name}
            </Option>
          ))}
        </Select>
        {name === 'goal' &&
          options.map(
            (option) =>
              option.value === macronutrientAnswers[name] && (
                <p
                  className='subtitle white'
                  style={{ margin: '10px 0 0 10px' }}
                >
                  {option.explanation}
                </p>
              )
          )}
      </div>
    );
  };

  const getLabelWithUnit = (label, name) => {
    console.log(`name: ${name}, unit: ${macronutrientAnswers.unit}`);
    if (name === 'weight') {
      return macronutrientAnswers.unit === 'imperial'
        ? `${label} (${t('lbs')})`
        : `${label} (${t('kg')})`;
    }
    if (name === 'height') {
      return macronutrientAnswers.unit === 'imperial'
        ? `${label} (${t('feet/inches')})`
        : `${label} (${t('cm')})`;
    }
    if (name === 'bodyFatPercentage') {
      return t(label);
    }
    return t(label);
  };

  const renderInputField = (label, name, placeholder) => {
    return (
      <div className='input-container mb-20'>
        <p className='label white'>{getLabelWithUnit(label, name)}</p>
        <Input
          onChange={(e) => {
            handleDropdownValue(name, Number(e.target.value));
          }}
          type='number'
          value={macronutrientAnswers[name] || ''}
          placeholder={placeholder}
        />
      </div>
    );
  };

  const validateMacronutrientAnswers = (answers) => {
    const errors = {};

    Object.keys(answers).forEach((key) => {
      const value = answers[key];

      if (value === '' || value === null || value === 0) {
        errors[key] = `${capitalize(key)} is required`;
      }
    });

    return errors;
  };

  const calculateBMR = (macroAnswers) => {
    let feet, inches;
    let heightInCm;

    if (macroAnswers.unit === 'imperial') {
      [feet, inches] = macroAnswers.height.split("'");
      heightInCm = feetToCentimeters(Number(feet), Number(inches));
    } else if (macroAnswers.unit === 'metric') {
      heightInCm = Number(macroAnswers.height);
    }

    let bmrFormula = macroAnswers.bmrFormula;
    let weight =
      macroAnswers.unit === 'imperial'
        ? Number(macroAnswers.weight) * 0.453592
        : macroAnswers.weight;
    let age = Number(macroAnswers.age);
    let lbm = weight * (1 - macroAnswers.bodyFatPercentage / 100);
    let bmr = 0;

    if (macroAnswers.gender === 'male') {
      if (bmrFormula === 'mifflin-st-jeor') {
        // Mifflin-St Jeor (Recommended):
        bmr = 10 * weight + 6.25 * heightInCm - 5 * age + 5;
      } else if (bmrFormula === 'harris-benedict') {
        // Harris-Benedict:
        bmr = 88.362 + 13.397 * weight + 4.799 * heightInCm - 5.677 * age;
      } else {
        // Katch-McArdle:
        bmr = 370 + 21.6 * lbm;
      }
    } else if (macroAnswers.gender === 'female') {
      if (bmrFormula === 'mifflin-st-jeor') {
        // Mifflin-St Jeor (Recommended):
        bmr = 10 * weight + 6.25 * heightInCm - 5 * age - 161;
      } else if (bmrFormula === 'harris-benedict') {
        // Harris-Benedict:
        bmr = 447.593 + 9.247 * weight + 3.098 * heightInCm - 4.33 * age;
      } else {
        // Katch-McArdle:
        bmr = 370 + 21.6 * lbm;
      }
    }
    return bmr;
  };

  const calculateCaloriesAndMacros = (bmr, callback) => {
    let { activityFactor, macroPreference, goal } = macronutrientAnswers;
    const maintenanceCalories = Math.round(bmr * activityFactor);
    const goals = [
      { key: 'rapid-weight-loss', adjustment: -1000 },
      { key: 'moderate-weight-loss', adjustment: -500 },
      { key: 'slow-weight-loss', adjustment: -250 },
      { key: 'maintain-weight', adjustment: 0 },
      { key: 'slow-weight-gain', adjustment: 250 },
      { key: 'moderate-weight-gain', adjustment: 500 },
      { key: 'rapid-weight-gain', adjustment: 1000 },
    ];

    // Function to calculate macros based on calorie percentages
    const calculateMacros = (
      calories,
      proteinPercent,
      carbPercent,
      fatPercent
    ) => ({
      protein: Math.round((proteinPercent * calories) / 4),
      carbs: Math.round((carbPercent * calories) / 4),
      fat: Math.round((fatPercent * calories) / 9),
    });

    const macroRatios = {
      balanced: [0.35, 0.35, 0.3],
      'low-carb': [0.4, 0.2, 0.4],
      'high-carb': [0.3, 0.5, 0.2],
      'high-protein': [0.4, 0.3, 0.3],
      ketogenic: [0.4, 0.1, 0.5],
      custom: [
        customMacros.protein / 100,
        customMacros.carbs / 100,
        customMacros.fat / 100,
      ],
    };

    let macrosForEachGoal = {};

    // Loop through goals and assign macros for each one based on the preference
    goals.forEach(({ key, adjustment }) => {
      const calories = maintenanceCalories + adjustment;
      const [proteinPercent, carbPercent, fatPercent] =
        macroRatios[macroPreference] || macroRatios['balanced'];

      macrosForEachGoal[key] = {
        calories,
        description:
          questionOptions(t)
            .goal.options.find((option) => option.value === key)
            ?.name.split('~')[1] || '',
        ...calculateMacros(calories, proteinPercent, carbPercent, fatPercent),
        isSelected: key === goal,
      };
    });

    dispatch({
      type: 'SET_MACRO_CALCULATION_RESULTS',
      payload: {
        bmr: Math.round(bmr),
        maintenanceCalories,
        macrosForEachGoal,
      },
    });

    callback?.();
  };

  const handleCalculateAndShowResults = () => {
    const errors = validateMacronutrientAnswers(macronutrientAnswers);

    if (Object.keys(errors).length === 0) {
      setErrors({});

      if (auth && auth.isAuthenticated && auth.user) {
        dispatch(saveMacronutrientAnswers(macronutrientAnswers));
      }

      const bmr = calculateBMR(macronutrientAnswers);

      calculateCaloriesAndMacros(bmr, () => {
        if (!auth.isAuthenticated) {
          setIsCalculating(true);
        }

        const timeoutDuration = auth.isAuthenticated ? 0 : 6000;
        setTimeout(() => {
          setShouldNavigate(true); // Trigger navigation in useEffect
          if (!auth.isAuthenticated) {
            setIsCalculating(false);
          }
        }, timeoutDuration);
      });
    } else {
      setErrors(errors);
    }
  };

  const features = !auth.isAuthenticated
    ? [
        { icon: '✅', text: 'Start in seconds! Quick, easy, and stress-free' },
        { icon: '✅', text: 'Use simple ingredients you know and love' },
        {
          icon: '✅',
          text: 'Customize your plan to match your taste and lifestyle',
        },
        {
          icon: '✅',
          text: 'Eliminate nutrition guesswork and get results faster',
        },
      ]
    : mealPlanFeatures;

  return (
    <>
      {isCalculating ? (
        <MacroLoading
          onComplete={() => {
            setIsCalculating(false);
            setShouldNavigate(true);
          }}
        />
      ) : (
        <div className='tool-heading-container landing-page black-background'>
          <div className='tool-heading-content'>
            <div className='title-container'>
              {auth && auth.isAuthenticated && auth.user ? (
                <h1 className='welcome-title'>{`Welcome back, ${auth.user.name}!`}</h1>
              ) : (
                <h1 className='welcome-title'>{t('landingPage.title')}</h1>
              )}
              {auth && auth.isAuthenticated && auth.user ? (
                <p className='subtitle mb-20'>
                  Now you have access exclusive benefits!
                </p>
              ) : (
                <p className='subtitle mb-20'>{t('landingPage.description')}</p>
              )}
              {features.map((feature, index) => (
                <p key={index} style={{ fontSize: '16px', margin: '10px 0' }}>
                  <div className='flex mb-10'>
                    <p className='mr-10'>{feature.icon}</p>
                    <p className='subtitle'>{feature.text}</p>
                  </div>
                </p>
              ))}
            </div>
            <div className='tools-page'>
              <div id='widgetContainer' className='tool'>
                <MacronutrientCalculatorComponent
                  {...props}
                  questionOptions={questionOptions}
                  renderDropdown={renderDropdown}
                  renderInputField={renderInputField}
                  macronutrientAnswers={macronutrientAnswers}
                  handleCalculateAndShowResults={handleCalculateAndShowResults}
                  handleDropdownValue={handleDropdownValue}
                  errors={errors}
                  setIsAdvancedMode={setIsAdvancedMode}
                  isAdvancedMode={isAdvancedMode}
                  heightOptions={heightOptions}
                  isMobileView={isMobileView}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
