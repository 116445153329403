import { put, takeLatest, call } from 'redux-saga/effects';
import axios from 'axios';
import {
  REGISTER,
  LOAD_USER,
  LOGIN_USER,
  REQUEST_RESET_EMAIL,
  RESET_PASSWORD,
} from './constants';
import {
  registerSuccess,
  registerError,
  loadUserSuccess,
  loadUserError,
  loginUserSuccess,
  loginUserError,
  requestResetEmailSuccess,
  requestResetEmailError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';
import setAuthToken from '../../helpers/utils';
import { addToMailChimp } from '../../helpers/utils';

const API_URL = process.env.REACT_APP_API_URL;

function normalizeError(err) {
  if (typeof err === 'string') {
    return [{ msg: err }]; // Handle string errors
  }
  if (
    err.response &&
    err.response.data &&
    typeof err.response.data === 'string'
  ) {
    return [{ msg: err.response.data }]; // Handle string errors sent as `response.data`
  }
  if (
    err.response &&
    err.response.data &&
    Array.isArray(err.response.data.errors)
  ) {
    return err.response.data.errors; // Return errors array from the response
  }
  if (err.response && err.response.data && err.response.data.message) {
    return [{ msg: err.response.data.message }]; // Single error message in response
  }
  if (err.message) {
    return [{ msg: err.message }]; // Handle generic error objects with a message property
  }
  return [{ msg: 'An unexpected error occurred' }]; // Fallback for unknown errors
}

export function* loadUser() {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const payload = yield call(axios.get, `${API_URL}/auth`);
    yield put(loadUserSuccess(payload.data));
  } catch (err) {
    console.log(err);
    localStorage.removeItem('token');
    const errors = normalizeError(err);
    yield put(loadUserError(errors));
  }
}

export function* register({ name, lastName, email, password }) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    name,
    lastName,
    email,
    password,
  });

  const isInvalidEmailError = (error) => {
    return (
      error.response &&
      error.response.status === 400 &&
      error.response.data &&
      typeof error.response.data.error === 'string' &&
      error.response.data.error.includes('looks fake or invalid')
    );
  };

  try {
    // Validate email with Mailchimp first
    try {
      yield call(addToMailChimp, { name, lastName, email }, [
        'Meal Plan Genie',
      ]);
    } catch (mailchimpError) {
      if (isInvalidEmailError(mailchimpError)) {
        console.error(
          'Mailchimp rejected email:',
          mailchimpError.response?.data?.error || 'Unknown error'
        );

        yield put(
          registerError(normalizeError(mailchimpError.response.data.error))
        );
        return; // Stop registration process
      }
      console.error('Non-blocking Mailchimp error:', mailchimpError.message);
    }

    // Proceed with user registration
    const payload = yield call(axios.post, '/api/users', body, config);

    // Save token in localStorage
    localStorage.setItem('token', payload.token);

    // Set the Authorization header globally
    setAuthToken(payload.token);

    // Dispatch success action
    yield put(registerSuccess(payload.data));
  } catch (err) {
    const errors = normalizeError(err);
    console.log(errors);
    yield put(registerError(errors));
  }
}

export function* loginUser({ email, password }) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ email, password });
  try {
    const payload = yield call(axios.post, '/api/auth', body, config);

    const token = payload.data.token;

    localStorage.setItem('token', token);

    yield put(loginUserSuccess(payload.data));
  } catch (err) {
    const errors = normalizeError(err);
    console.log(errors);
    yield put(loginUserError(errors));
  }
}

export function* requestResetEmail(email) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(email);

  try {
    const payload = yield call(
      axios.post,
      '/api/auth/request-reset',
      body,
      config
    );

    const token = payload.data.token;
    localStorage.setItem('token', token);

    yield put(requestResetEmailSuccess(payload.data));
  } catch (err) {
    const errors = normalizeError(err);
    console.log(errors);
    yield put(requestResetEmailError(errors));
  }
}

export function* resetPassword({ email, token, newPassword }) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ email, token, newPassword });

  try {
    const payload = yield call(
      axios.post,
      '/api/auth/reset-password',
      body,
      config
    );

    const token = payload.data.token;
    localStorage.setItem('token', token);

    yield put(resetPasswordSuccess(payload.data));
  } catch (err) {
    const errors = normalizeError(err);
    console.log(errors);
    yield put(resetPasswordError(errors));
  }
}

export default function* authSaga() {
  yield takeLatest(REGISTER, register);
  yield takeLatest(LOAD_USER, loadUser);
  yield takeLatest(LOGIN_USER, loginUser);
  yield takeLatest(REQUEST_RESET_EMAIL, requestResetEmail);
  yield takeLatest(RESET_PASSWORD, resetPassword);
}
