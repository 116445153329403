import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Register } from '../../pages/register';
import { Login } from '../../pages/login';

export const RegisterModal = ({ isVisible }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const macronutrientAnswersLoading = useSelector(
    (state) => state.global.macronutrientAnswersLoading
  );

  useEffect(() => {
    if (
      auth.isAuthenticated &&
      auth.user &&
      !macronutrientAnswersLoading &&
      !auth.registrationLoading
    ) {
      dispatch({ type: 'OPEN_AUTH_MODAL', payload: false });
    }
  }, [
    auth.isAuthenticated,
    auth.user,
    dispatch,
    macronutrientAnswersLoading,
    auth.registrationLoading,
  ]);

  return (
    <Modal
      className='auth-modal'
      open={isVisible}
      onCancel={() => {
        dispatch({ type: 'CLEAR_AUTH_ERROR' });
        dispatch({ type: 'OPEN_AUTH_MODAL', payload: !isVisible });
      }}
      footer={null}
      centered
      width={!isLogin ? 850 : 450}
      closable={true}
    >
      <>
        {isLogin ? (
          <Login isModal={true} isLogin={true} />
        ) : (
          <Register isModal={true} isRegister={true} />
        )}
      </>
    </Modal>
  );
};
