import React, { useEffect, useRef, useState } from 'react';
import Peer from 'simple-peer';
import { getSocket } from '../../services/socket';
import { Button } from 'antd';
import './index.css';

const LiveStream = ({ auth }) => {
  const socket = useRef(null);
  const isAdmin =
    auth.isAuthenticated && auth.user && auth.user.role === 'admin';
  const [localStream, setLocalStream] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [peers, setPeers] = useState([]);
  const userVideo = useRef();
  const peersRef = useRef([]);

  // Initialize the socket connection and capture camera once
  useEffect(() => {
    socket.current = getSocket();

    // Capture video for admin preview
    if (isAdmin) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((mediaStream) => {
          setLocalStream(mediaStream);
          if (userVideo.current) {
            userVideo.current.srcObject = mediaStream;
          }
        })
        .catch((err) => {
          console.error('Error accessing media devices:', err);
        });
    }

    // Cleanup on unmount
    return () => {
      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
      }
      if (socket.current) {
        socket.current.off('send-stream-to-user');
        socket.current.off('signal');
        if (isStreaming) {
          socket.current.emit('admin-end-stream');
        }
      }
    };
  }, [isAdmin]); // Only run on initial mount or isAdmin change

  // Set up socket listeners when streaming state changes
  useEffect(() => {
    if (!socket.current) return;

    // Clear previous listeners
    socket.current.off('send-stream-to-user');
    socket.current.off('signal');

    if (isStreaming && localStream) {
      // Set up event listeners for streaming
      socket.current.on('send-stream-to-user', ({ userId }) => {
        console.log(`Sending stream to viewer: ${userId}`);
        // Check if we already have a peer for this user
        const existingPeer = peersRef.current.find((p) => p.id === userId);
        if (!existingPeer) {
          const peer = createPeer(userId, socket.current.id, localStream);
          peersRef.current.push({ id: userId, peer });
          setPeers((prev) => [...prev, { id: userId, peer }]);
        }
      });

      socket.current.on('signal', ({ signal, from }) => {
        console.log(`Got answer from viewer: ${from}`);
        const item = peersRef.current.find((p) => p.id === from);
        if (item) {
          item.peer.signal(signal);
        }
      });
    }
  }, [isStreaming, localStream]);

  // Function to start streaming to viewers
  const startStream = () => {
    if (!localStream) return;

    // Emit events to notify viewers that streaming has started
    socket.current.emit('admin-start-stream');
    socket.current.emit('join-room', 'live-room');
    setIsStreaming(true);
  };

  const stopStream = () => {
    // Emit an event to notify viewers that the admin has ended the stream
    if (socket.current) {
      socket.current.emit('admin-end-stream');
    }

    // Destroy all peer connections
    peersRef.current.forEach(({ peer }) => {
      if (peer && typeof peer.destroy === 'function') {
        peer.destroy();
      }
    });

    // Clear the list of peers
    setPeers([]);
    peersRef.current = [];

    // Update state to reflect that streaming has stopped
    setIsStreaming(false);

    // Note: We're NOT stopping and restarting the camera here
    // This keeps the preview working without interruption
  };

  // Create peer connection with a viewer
  function createPeer(userToSignal, callerId, streamObj) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream: streamObj,
    });

    peer.on('signal', (signal) => {
      socket.current.emit('signal', {
        target: userToSignal,
        signal,
        from: callerId,
      });
    });

    return peer;
  }

  return (
    isAdmin && (
      <div className='live-stream-container'>
        <div className='column-left'>
          <video
            ref={userVideo}
            autoPlay
            muted
            style={{
              height: 'calc(100vh - 96px)',
              width: '100%',
            }}
          />
        </div>

        <div className='column-right'>
          {!isStreaming ? (
            <Button type='primary' onClick={startStream}>
              Start Stream
            </Button>
          ) : (
            <>
              <p className='mb-10'>Streaming is live!</p>
              <p>Connected viewers: {peers.length}</p>
              <Button className='mt-20' type='primary' onClick={stopStream}>
                End Stream
              </Button>
            </>
          )}
        </div>
      </div>
    )
  );
};

export default LiveStream;
