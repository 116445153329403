import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { mealPlanFeatures } from '../../data/features';
import Confetti from 'react-confetti';

export const WelcomeModal = () => {
  const dispatch = useDispatch();
  const isWelcomeModalVisible = useSelector(
    (state) => state.global.isWelcomeModalVisible
  );
  const user = useSelector((state) => state.auth.user);

  return (
    <Modal
      className='auth-modal dark'
      open={isWelcomeModalVisible}
      onCancel={() => {
        dispatch({
          type: 'HANDLE_WELCOME_MODAL',
          payload: false,
        });
      }}
      footer={null}
      centered
      width={450}
      closable={true}
    >
      <Confetti
        width={450}
        height={window.innerHeight}
        recycle={false}
        numberOfPieces={250}
      />
      <div className='auth-container'>
        <div className='mb-20'>
          <p className='small-title text-center mb-10'>
            {`Welcome to Meal Plan Genie, ${user && user.name}! 🎉`}
          </p>
          <p className='subtitle text-center'>
            You now have access to exclusive features what will make meal
            planning a breeze:
          </p>
        </div>
        <div className='mb-20'>
          {mealPlanFeatures.map((feature, index) => (
            <p key={index} style={{ fontSize: '16px', margin: '10px 0' }}>
              <div className='flex mb-10'>
                <p
                  style={{
                    marginRight: '8px',
                    marginTop: '2px',
                  }}
                >
                  {feature.icon}
                </p>
                <p className='subtitle white'>{feature.text}</p>
              </div>
            </p>
          ))}
        </div>

        <p className='subtitle text-center'>
          Follow me on Instagram{' '}
          <a
            className='text-gradient'
            href='https://www.instagram.com/alvatros'
            target='_blank'
            rel='noopener noreferrer'
          >
            @alvatros
          </a>{' '}
          for behind the scenes and announcements as we build Meal Plan Genie
          together! 🧞‍♂️✨
        </p>
      </div>
    </Modal>
  );
};
