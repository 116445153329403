import {
  REGISTER,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR,
  AUTH_ERROR,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT,
  REQUEST_RESET_EMAIL,
  REQUEST_RESET_EMAIL_SUCCESS,
  REQUEST_RESET_EMAIL_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from './constants';

export function register({ name, lastName, email, password }) {
  return {
    type: REGISTER,
    name,
    lastName,
    email,
    password,
  };
}

export function registerSuccess(payload) {
  return {
    type: REGISTER_SUCCESS,
    payload,
  };
}

export function registerError(errors) {
  return {
    type: REGISTER_ERROR,
    errors,
  };
}

export function requestResetEmail(email) {
  return {
    type: REQUEST_RESET_EMAIL,
    email,
  };
}

export function requestResetEmailSuccess(payload) {
  return {
    type: REQUEST_RESET_EMAIL_SUCCESS,
    payload,
  };
}

export function requestResetEmailError(errors) {
  return {
    type: REQUEST_RESET_EMAIL_ERROR,
    errors,
  };
}

export function resetPassword({ email, token, newPassword }) {
  return {
    type: RESET_PASSWORD,
    email,
    token,
    newPassword,
  };
}

export function resetPasswordSuccess(payload) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload,
  };
}

export function resetPasswordError(errors) {
  return {
    type: RESET_PASSWORD_ERROR,
    errors,
  };
}

export function loadUser() {
  return {
    type: LOAD_USER,
  };
}

export function loadUserSuccess(payload) {
  return {
    type: LOAD_USER_SUCCESS,
    payload,
  };
}

export function loadUserError(errors) {
  return {
    type: LOAD_USER_ERROR,
    errors,
  };
}

export function loginUser({ email, password }) {
  return {
    type: LOGIN_USER,
    email,
    password,
  };
}

export function loginUserSuccess(payload) {
  return {
    type: LOGIN_USER_SUCCESS,
    payload,
  };
}

export function loginUserError(errors) {
  return {
    type: LOGIN_USER_ERROR,
    errors,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}
