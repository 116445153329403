import React, { useState, useEffect } from 'react';
import { Input, Button, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { requestResetEmail, resetPassword } from '../ducks/Auth/actions';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const ResetPassword = ({ isModal, isResetPassword }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const serverErrors = useSelector((state) => state.auth.errors);
  const serverSuccess = useSelector((state) => state.auth.success);
  const token = searchParams.get('token');
  const emailFromUrl = searchParams.get('email');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [localError, setLocalError] = useState('');

  useEffect(() => {
    if (emailFromUrl) setEmail(emailFromUrl);
  }, [emailFromUrl]);

  useEffect(() => {
    if (serverSuccess && serverSuccess === 'Password successfully updated') {
      setNewPassword('');
      setConfirmPassword('');
    }
  }, [serverSuccess]);

  const handleRequestReset = () => {
    if (!email) {
      setLocalError('Please enter your email address');
      return;
    }
    setLocalError('');
    dispatch(requestResetEmail({ email }));
  };

  const handleResetPassword = () => {
    if (!newPassword || !confirmPassword) {
      setLocalError('Please fill in all password fields');
      return;
    }
    if (newPassword !== confirmPassword) {
      setLocalError('Passwords do not match');
      return;
    }
    setLocalError('');
    dispatch(resetPassword({ email, token, newPassword }));
  };

  return (
    <div
      className={`auth-page ${isModal ? 'modal' : ''}`}
      style={{ height: isResetPassword ? '100vh' : '' }}
    >
      <div
        className={`auth-container ${isModal ? 'modal' : ''}`}
        style={{ width: isResetPassword ? '450px' : '' }}
      >
        <p className='title'>
          {token ? 'Reset Your Password' : 'Forgot Password'}
        </p>
        <p className='text-center mb-10' style={{ fontSize: '16px' }}>
          {token
            ? 'Enter a new password to reset your account.'
            : "Enter your email address and we'll send you a link to reset your password."}
        </p>
        <Space direction='vertical'>
          {!token && (
            <>
              <Input
                className='register-input'
                placeholder='Email address'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                style={{ borderRadius: '5px' }}
                className='full-width-button'
                onClick={handleRequestReset}
                type='primary'
                size='large'
              >
                Email Me
              </Button>
              <Link
                onClick={() => {
                  dispatch({ type: 'SET_IS_LOGIN', payload: true });
                  dispatch({ type: 'CLEAR_AUTH_ERROR' });
                }}
                style={{ fontSize: '16px' }}
                to={!isModal && '/login'}
              >
                Back To Log in
              </Link>
            </>
          )}
          {token && (
            <>
              <Input
                className='register-input'
                placeholder='New Password'
                type='password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <Input
                className='register-input'
                placeholder='Confirm Password'
                type='password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                style={{ borderRadius: '5px' }}
                className='full-width-button'
                onClick={handleResetPassword}
                type='primary'
                size='large'
              >
                Reset Password
              </Button>
            </>
          )}
        </Space>
        {localError && (
          <p
            className='text-center'
            style={{ color: 'red', marginTop: '10px' }}
          >
            {localError}
          </p>
        )}
        {!localError &&
          !serverSuccess &&
          serverErrors &&
          serverErrors.length > 0 && (
            <>
              {serverErrors.map((error, index) => (
                <p
                  key={index}
                  className='text-center'
                  style={{ color: 'red', marginTop: '10px' }}
                >
                  {error.msg}
                </p>
              ))}
              {serverErrors.some(
                (error) =>
                  error.msg ===
                  'The reset link is invalid or expired. Please request a new one.'
              ) && (
                <Button
                  onClick={() => dispatch(requestResetEmail({ email }))}
                  className='mt-10'
                  type='default'
                >
                  Request New Token
                </Button>
              )}
            </>
          )}

        {serverSuccess && (
          <p
            className='text-center'
            style={{ color: 'green', marginTop: '10px' }}
          >
            {serverSuccess}
          </p>
        )}
        {serverSuccess === 'Password successfully updated' && (
          <Button
            className='mt-10'
            onClick={() => {
              dispatch({ type: 'CLEAR_AUTH_ERROR' });
              navigate('/login');
            }}
          >
            Login
          </Button>
        )}
      </div>
    </div>
  );
};
